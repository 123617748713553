import FacElement from './FacElement';

const SideBar = () => {
  return (
    <aside className="side_bar">
      <FacElement icon="fas fas fa-seedling  fa-lg" type="connection" ElementName="Input Resources" />
      <FacElement icon="fas fa-industry fa-lg" type="process" ElementName="Electrolyser" />
      <FacElement icon="fas fa-industry fa-lg" type="process" ElementName={`NH\u2083 plant`} />
      <FacElement icon="fas fa-database fa-lg" type="storage" ElementName="Storage" />
      <FacElement icon="fab fa-connectdevelop fa-lg" type="node" ElementName="Distributor" />
      <FacElement icon="fas fas fa-seedling  fa-lg" type="connection" ElementName="Output Resources" />
      <FacElement icon="fas fa-industry fa-lg" type="process" ElementName="Process plant" />

      {/* <FacElement icon="fas fa-car-battery fa-lg" type="battery" ElementName="Battery Node" />
      <FacElement icon="fas fa-water fa-lg" type="reshydro" ElementName="Reservoir Hydro" />
      <FacElement icon="fab fa-buffer fa-lg" type="delaybuffer" ElementName="Delay Buffer" /> */}
    </aside>
  );
};

export default SideBar;
