import { toggleActions } from 'components/Store/TogglePopup';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Box, Button, Grid, Paper, styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { elementActions } from 'components/Store/ElementSlice';
import './SelectFac.css';
import '../ElementForms/Form.css';
import { sortBy } from 'lodash';
import { NotificationActions } from 'components/Store/SendNotifications';
import { useConfirm } from 'material-ui-confirm';
import deleteaction from 'components/Store/delete-action';
import SendData from 'components/SaveData/SendData';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpAZ, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faArrowUpAZ);

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'centre',
  background: 'none',
  boxShadow: 'none',
  fontSize: '1.3rem',
  paddingRight: '1.5rem',
  alignItems: 'center',
  color: 'white',
  lineHeight: 3.443,
}));

let SelectFac = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  const [id, setId] = useState();
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const history = useHistory();

  // const ElementData = useSelector((state) => state.comp);

  const togglePopup = () => {
    dispatch(toggleActions.setIsLoaded());
  };
  // console.log('drop', JSON.stringify(props.facData.data, null, 2));
  let selectFacName = (value) => {
    let dataFac = props.facData.data.filter((e) => e.facilityId.toString() === value.FacilityId.toString());
    dispatch(elementActions.RestoreElement(dataFac[0]));
    dispatch(toggleActions.getIsLoadRun(dataFac[0].run));
    dispatch(toggleActions.setFacilityLoaded(true));
    if (dataFac[0].isPrioritySet === false) {
      dispatch(toggleActions.setIsNotif(true));
      dispatch(
        NotificationActions.showNotification({
          status: 'error',
          title: 'fail',
          message: 'Selected facility is from old version. Please specify the connection priorities!',
        })
      );
    }
    history.push('/App');
    togglePopup();
  };

  var getDescription = (id) => {
    let description = props.facData?.data
      .filter((data) => data.facilityId.toString() === id)
      .map((d) => {
        return d.description;
      });

    return description;
  };

  var getDate = (id) => {
    let date = props.facData?.data
      .filter((data) => data.facilityId.toString() === id)
      .map((d) => {
        return new Date(d.updatedAt).toLocaleDateString();
      });

    return date;
  };

  var getRun = (id) => {
    let isRun = props.facData?.data
      .filter((data) => data.facilityId.toString() === id)
      .map((d) => {
        return d.run;
      });
    if (isRun) {
      if (isRun[0] === true) {
        return 'True';
      } else if (isRun[0] === false) {
        return 'False';
      } else if (isRun === []) {
        return '';
      }
    }
  };

  let filter = props.facData?.data.filter((data) => {
    if (data.deleteFacility) {
      return data.deleteFacility === false;
    } else {
      return data;
    }
  });

  const [sortedFac, setSortedFac] = useState(
    sortBy(filter, function (dateObj) {
      return new Date(dateObj.updatedAt);
    }).reverse()
  );

  const onSorting = (e) => {
    console.log('so', e);
    let sort = e;
    if (sort === 'asc') {
      setSortedFac(
        sortBy(sortedFac, function (name) {
          return name.facilityName;
        })
      );
    } else if (sort === 'desc') {
      setSortedFac(
        sortBy(sortedFac, function (name) {
          return name.facilityName;
        }).reverse()
      );
    } else if (sort === 'updated') {
      setSortedFac(
        sortBy(sortedFac, function (dateObj) {
          return new Date(dateObj.updatedAt);
        }).reverse()
      );
    }
  };

  const deleteFacilityHandler = () => {
    confirm({ description: 'Do you want to delete this facility?' })
      .then(() => {
        dispatch(deleteaction()).then(() => {
          let ElementData = sortedFac.filter((el) => el.facilityId.toString() === id.toString());
          console.log('select data', ElementData[0]);

          let newData = { ...ElementData[0], deleteFacility: true };
          console.log('select data', newData);
          setTimeout(async () => {
            await SendData(newData);
          }, 2000);
        });
        // history.goBack();
        dispatch(toggleActions.setIsNotif(true));

        dispatch(
          NotificationActions.showNotification({
            status: 'success',
            title: 'Facility deleted',
            message: 'Facility is successfully deleted.',
          })
        );
        dispatch(toggleActions.setIsLoaded());
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  return (
    <div className="FacilitySelect">
      <div>
        <button className="close-btn" onClick={togglePopup}>
          <i className="far fa-window-close fa-2x icon"> </i>
        </button>
      </div>

      <form
        onSubmit={handleSubmit((values) => {
          selectFacName(values);
        })}
      >
        <div>
          <h2 className="box_title"> Choose a facility to load</h2>
          <Box sx={{ flexGrow: 1, width: '100%', alignItems: 'center' }}>
            <Grid container rowspacing={1} alignItems="center">
              <Grid item xs={4}>
                <label className="box_title">
                  <Item style={{ lineHeight: 1.2 }}>Facility Name:</Item>
                </label>
              </Grid>
              <Grid item xs={7}>
                <Field
                  component="select"
                  required
                  className="sortfac"
                  name="FacilityId"
                  id="FacilityName"
                  placeholder="Facility Name"
                  onChange={(event) => setId(event.target.value)}
                >
                  <option />
                  {sortedFac.map((data) => (
                    <option key={data.facilityId} value={data.facilityId}>
                      {data.facilityName}
                    </option>
                  ))}
                </Field>
              </Grid>
              <div>
                <Button
                  className="btn_none"
                  onClick={() => {
                    onSorting('asc');
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-arrow-up-a-z" size="xl" border />
                </Button>
                &nbsp;
                <Button
                  className="btn_none"
                  onClick={() => {
                    onSorting('updated');
                  }}
                >
                  <FontAwesomeIcon icon={faCalendarDays} size="xl" border />
                </Button>
              </div>

              <Grid item xs={4}>
                <label>
                  <Item>Description:</Item>
                </label>
              </Grid>
              <Grid item xs={8}>
                <p className="selectFac"> {getDescription(id)}</p>
              </Grid>
              <Grid item xs={4}>
                <label>
                  <Item>Date updated:</Item>
                </label>
              </Grid>
              <Grid item xs={8}>
                <p className="selectFac"> {getDate(id)}</p>
              </Grid>
              <Grid item xs={4}>
                <label>
                  <Item>Run Successfully:</Item>
                </label>
              </Grid>
              <Grid item xs={8}>
                <p className="selectFac"> {getRun(id)}</p>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="btn_ctn">
          <button type="submit" id="sub_form" className="sel" disabled={pristine || submitting}>
            Select
          </button>
          <button type="button" className="del" onClick={deleteFacilityHandler} id="delete_fac">
            Delete Facility
          </button>
        </div>
      </form>
    </div>
  );
};

SelectFac = reduxForm({
  form: 'SelectName',
})(SelectFac);

export default SelectFac;
