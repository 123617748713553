import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';
import { Collapse, IconButton } from '@material-ui/core';
import { toggleActions } from 'components/Store/TogglePopup';

const Notification = () => {
  const isNotif = useSelector((state) => state.toggleForm.isNotif);
  const isWarnNotif = useSelector((state) => state.toggleForm.isWarnNotif);
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(true);
  const notif = useSelector((state) => state.Notif.notification);
  const WarnNotif = useSelector((state) => state.Notif.warningNot);

  console.log('notifca', notif, isNotif);
  console.log('warn notif', WarnNotif, isWarnNotif);

  return (
    <div>
      {notif && (
        <div>
          <Collapse in={isNotif}>
            <Alert
              variant="filled"
              severity={notif.status}
              style={{
                color: 'black',
                fontSize: '16px',
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(toggleActions.setIsNotif(false));
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {notif.message}
            </Alert>
          </Collapse>
        </div>
      )}
      {isWarnNotif && WarnNotif && (
        <Collapse in={isWarnNotif}>
          <Alert
            variant="filled"
            severity={WarnNotif.status}
            style={{
              color: 'black',
              fontSize: '16px',
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(toggleActions.setIsWarnNotif(false));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {WarnNotif.message}
          </Alert>
        </Collapse>
      )}
    </div>
  );
};

export default Notification;
