import React, { useState, DragEvent, MouseEvent, useRef, useEffect, useCallback } from 'react';

import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  ControlButton,
  OnLoadParams,
  Connection,
  Position,
  ArrowHeadType,
  FlowElement,
  Edge,
  MiniMap,
} from 'react-flow-renderer';

import { useConfirm } from 'material-ui-confirm';
import ConnectionLine from '../Connectionline/ConnectionLine';
import './DragnDrop.css';
import Sidebar from '../SideBar/SideBar';
import Popup from '../PopupForm/Popup';
import ProcessForm from '../ElementForms/ProcessForm';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import MultiInput from '../Handles/MultiInput';
import MultiOutput from 'components/Handles/MultiOutput';
import SingleHandle from '../Handles/SingleHandle';
import FacilityForm from 'components/ElementForms/FacilityForm';
import { toggleActions } from 'components/Store/TogglePopup';
import '../Home/HomePage.css';
import StorageForm from 'components/ElementForms/StorageForm';
import NodeForm from 'components/ElementForms/NodeForm';
import BatteryForm from 'components/ElementForms/BatteryForm';
import DelaybufferForm from 'components/ElementForms/DelaybufferForm';
import ReshydroForm from 'components/ElementForms/ReshydroForm';
import ConfigForm from 'components/ElementForms/ConfigForm';
import ReactTooltip from 'react-tooltip';
import randomColor from 'randomcolor';
import ConnectionForm from 'components/ElementForms/ConnectionForm';
import GetData from 'components/Data/GetData';
import UploadFileForm from 'components/Utils/UploadFileForm';
import InputResourceHandle from 'components/Handles/InputResourceHandle';
import OutputResourceHandle from 'components/Handles/OutputResourceHandle';
import ExternalDataHandle from '../Handles/ExternalDataHandle';
import Joyride, { STATUS, ACTIONS } from 'react-joyride';
import steps from '../Data/TourSteps';

const NODE_TYPES = {
  process: MultiInput,
  battery: SingleHandle,
  reshydro: MultiOutput,
  delaybuffer: SingleHandle,
  storage: SingleHandle,
  connection: SingleHandle,
  External_Data: ExternalDataHandle,
  Input_Resources: InputResourceHandle,
  Output_Resources: OutputResourceHandle,
  node: MultiInput,
};

const DragnDrop = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const confirm = useConfirm();

  let [res, setRes] = useState<any>([]);
  let [ext, setExt] = useState<any>([]);
  let [nL, setNL] = useState<any>([]);
  const [elementType, setElementType] = useState('');
  const [elementId = [], setElementId] = useState<any>();

  const onLoad = (_reactFlowInstance: OnLoadParams) => {
    _reactFlowInstance.fitView();
    setReactFlowInstance(_reactFlowInstance);
  };

  useEffect(() => {
    const fetchRes = async () => {
      setRes(await GetData('resources'));
      setExt(await GetData('tsdata'));
      setNL(await GetData('scalingcurves'));
    };
    fetchRes();
  }, []);

  const zoomOnScroll = useSelector((state: any) => state.toggleForm.zoomOnScroll);
  const dispatch = useDispatch();

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };
  const isOpen = useSelector((state: any) => state.toggleForm.isOpen);

  const togglePopup = () => {
    dispatch(toggleActions.toggleElement());
  };
  const isFormOpen = useSelector((state: any) => state.toggleForm.formIsVisible);
  const isConfigOpen = useSelector((state: any) => state.toggleForm.isConfigOpen);
  const isUploadFile = useSelector((state: any) => state.toggleForm.isUploadFile);
  // const isLoaded = useSelector((state: any) => state.toggleForm.isLoaded);
  const runTour = useSelector((state: any) => state.toggleForm.runTour);

  // let [runTour, setRunTour] = useState<any>();

  const setRunTour = (ev: any) => {
    dispatch(toggleActions.setTour(ev));
  };
  const setIsFormOpen = () => {
    dispatch(toggleActions.toggleFacForm(!isFormOpen));
  };
  const setIsConfig = () => {
    dispatch(toggleActions.setIsConfigOpen());
  };

  const setIsUploadFile = () => {
    dispatch(toggleActions.uploadFile());
  };
  const ElementData = useSelector((state: any) => state.comp.components);
  var [countEl, setCountEL] = useState(ElementData && ElementData.length > 0 ? ElementData.length : 1);

  console.log('ElementData', ElementData);

  const fac = useSelector((state: any) => state.comp);
  const addElementToStore = (data: any) => {
    dispatch(elementActions.addElement(data));
  };

  const addConnectionToStore = (data: any) => {
    dispatch(elementActions.addConnection(data));
  };
  const un_id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

  const makeid = (type: string) => {
    return type + '_' + un_id;
  };

  const makeColor = () => {
    return randomColor({
      luminosity: 'light',
      format: 'hex',
      alpha: 1,
    });
  };

  // assign type to elemets
  const getNodetype = (NodeData: any) => {
    var NodeType = '';
    if (NodeData.ElementName === 'Input Resources') {
      NodeType = 'Input_Resources';
    } else if (NodeData.ElementName === 'Output Resources') {
      NodeType = 'Output_Resources';
    } else if (NodeData.ElementName === 'External_Data') {
      NodeType = 'External_Data';
    } else {
      NodeType = NodeData.type;
    }
    return NodeType;
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    setCountEL(countEl + 1);
    if (reactFlowInstance && event.dataTransfer.getData('application/reactflow')) {
      const NodeData = JSON.parse(event.dataTransfer.getData('application/reactflow'));

      const position = reactFlowInstance.project({
        x: event.clientX,
        y: event.clientY - 40,
      });
      const sourcePosition = Position.Right;
      const targetPosition = Position.Left;

      let nodeType = getNodetype(NodeData);
      const Elid = makeid(nodeType);

      console.log('nodetype', nodeType);

      addElementToStore({
        id: Elid,
        type: nodeType,
        position,
        className: NodeData.icon,
        sourcePosition,
        targetPosition,
        data: {
          id: un_id,
          UIid: Elid,
          type: `${NodeData.type}`,
          isValidated: false,
          color: makeColor(),
          label: `${NodeData.ElementName}`,
          elNumber: countEl,
        },
      });
    }
  };

  // to store the positions of the elements
  const onNodeDragStop = (event: any, node: any) => {
    addElementToStore(node);
  };

  const makeConnection = useCallback(
    (edgeid: any, source: any, sourceHandle: any, target: any, targetHandle: any, newCount) => {
      dispatch(
        elementActions.addConnection(
          addEdge(
            {
              source: source,
              sourceHandle: sourceHandle,
              target: target,
              targetHandle: targetHandle,
              id: 'connection_' + edgeid,
              type: 'smoothstep',
              arrowHeadType: ArrowHeadType.Arrow,
              label: `${source.split('_')[0]}_to_${target.split('_')[0]}`,
              labelStyle: { fill: 'black', fontWeight: 700, color: '#fff0', fontSize: '20' },
              data: {
                id: edgeid,
                UIid: 'connection_' + edgeid,
                type: 'connection',
                input_custom_priority: 0,
                output_custom_priority: 0,
                isValidated: false,
                elNumber: newCount,
              },
            },
            ElementData
          )
        )
      );
    },
    [dispatch, ElementData]
  );

  const makeDistributer = useCallback(
    (id: any, position: any, newCount) => {
      var x = position.x + 300;
      var y = position.y;

      dispatch(
        elementActions.addElement({
          id: 'node_' + id,
          type: 'node',
          position: { x: x, y: y },
          className: 'fab fa-connectdevelop fa-lg',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,

          data: {
            id: id,
            UIid: 'node_' + id,
            type: 'node',
            isValidated: false,
            color: makeColor(),
            label: 'Distributor',
            elNumber: newCount,
          },
        })
      );
    },
    [dispatch]
  );

  const ifNodeExists = (ifExists: any) => {
    if (ifExists.length === 2 || ifExists.length === 4) {
      if (ifExists.at(-2).target === ifExists.at(-1).source) {
        return true;
      } else {
        return false;
      }
    } else if (ifExists.length === 3) {
      if (ifExists.at(0).target === ifExists.at(1).source) {
        return true;
      } else {
        return false;
      }
    }
  };

  const AddDistributor = (params: Connection | Edge) => {
    const ifExists = ElementData.filter(
      (e: any) => e.data.type === 'connection' && (e.source === params.source || e.target === params.target)
    );
    if (!ifNodeExists(ifExists)) {
      console.log('el in add dis', ElementData, ElementData.length);

      var len = ElementData.length + 1;
      const ps = ElementData.find((e: any) => e.id === params.source);
      const id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

      makeDistributer(id, ps.position, len);

      const edgeid1 = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
      makeConnection(edgeid1, params.source, 'process_right5', 'node_' + id, 'node_left4', len + 1);

      const edgeid2 = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

      makeConnection(edgeid2, 'node_' + id, 'node_right4', params.target, 'process_left5', len + 2);

      setCountEL(countEl + 3);
    }
  };

  const getLabel = (params: Connection | Edge) => {
    let conEl_Sr = ElementData.filter((e: any) => e.id === params?.source);
    let conEl_tr = ElementData.filter((e: any) => e.id === params?.target);

    let SrLabel = conEl_Sr.map((e: any) => e.data.label);
    let TrLabel = conEl_tr.map((e: any) => e.data.label);

    return [SrLabel, TrLabel];
  };

  const onConnect = (params: Connection | Edge) => {
    setCountEL(countEl + 1);
    if (params.source?.startsWith('process') && params.target?.startsWith('process')) {
      AddDistributor(params);
    } else {
      const arrowHeadType = ArrowHeadType.Arrow;

      var EdgeID = makeid('connection');
      let Con_label = getLabel(params);

      console.log('label', Con_label);

      addConnectionToStore(
        addEdge(
          {
            ...params,
            id: EdgeID,
            type: 'smoothstep',
            arrowHeadType,
            label: `${Con_label[0]}_to_${Con_label[1]}`,
            labelStyle: { fill: 'black', fontWeight: 700, color: '#fff0', fontSize: '20' },
            data: {
              id: un_id,
              UIid: EdgeID,
              output_custom_priority: 0,
              input_custom_priority: 0,
              type: 'connection',
              isValidated: false,
              elNumber: countEl,
            },
          },
          ElementData
        )
      );
    }
    dispatch(elementActions.modifyData());
  };

  const onElementClick = (event: MouseEvent, element: FlowElement) => {
    let clickElement = ElementData.filter((e: any) => e.id === element.id);
    console.log('el click', clickElement[0], clickElement[0].data.elNumber);
    if (
      element.type === 'smoothstep' &&
      (['External', 'Input'].includes(clickElement[0].source.split('_')[0]) ||
        ['External', 'Output'].includes(clickElement[0].target.split('_')[0]))
    ) {
      setElementId('');
      setElementType('');
      console.log('connection clicked', element);
      confirm({ description: 'Do you want to delete this connection?', confirmationText: 'Delete' })
        .then(() => {
          dispatch(elementActions.removeElement({ id: element.id }));
        })
        .catch(() => console.log('Deletion cancelled.'));
    } else {
      setElementId(element.id);
      setElementType(element.data.type);
      togglePopup();
    }
  };

  const messagesEndRef = useRef<HTMLInputElement>(null);

  const scrollToUp = () => {
    messagesEndRef?.current?.scrollTo({
      top: 175,
      behavior: 'smooth',
    });
    document?.getElementById('popupForm')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(scrollToUp, []);

  const onEdgeUpdate = (oldEdge: any, newConnection: any) => {
    console.log('update');
    oldEdge = {
      ...oldEdge,
      target: newConnection.target,
      source: newConnection.source,
      sourceHandle: newConnection.sourceHandle,
      targetHandle: newConnection.targetHandle,
    };

    dispatch(elementActions.updateConnection(oldEdge));
  };

  const handleJoyrideCallback = (data: { action: any; index: any; status: any; type: any }) => {
    const { status, action, type } = data;
    if ([STATUS.RUNNING, STATUS.READY, STATUS.WAITING].includes(status)) {
      dispatch(toggleActions.toggleFacForm(false));

      dispatch(
        elementActions.addElement({
          id: 'Input_Resources_19830564551',
          type: 'Input_Resources',
          position: { x: 142, y: 286 },
          className: 'fas fas fa-seedling  fa-lg',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,

          data: {
            id: 19830564551,
            UIid: 'Input_Resources_19830564551',
            type: 'connection',
            input_custom_priority: 0,
            output_custom_priority: 0,
            isValidated: false,
            color: makeColor(),
            label: 'Input_Resources',
          },
        })
      );
      dispatch(
        elementActions.addElement({
          id: 'process_492590947697',
          type: 'process',
          position: { x: 762, y: 326 },
          className: 'fas fas fa-seedling  fa-lg',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,

          data: {
            id: 492590947697,
            UIid: 'process_492590947697',
            type: 'process',
            isValidated: false,
            color: makeColor(),
            label: 'process',
          },
        })
      );
      dispatch(
        elementActions.addConnection(
          addEdge(
            {
              id: 'connection_322190635448',
              type: 'smoothstep',
              targetHandle: 'process_left5',
              target: 'process_492590947697',
              sourceHandle: 'Input_right',
              source: 'Input_Resources_19830564551',
              label: 'input_to_process',
              labelStyle: { fill: 'black', fontWeight: 700, color: '#fff0', fontSize: '20' },
              data: {
                id: 22190635448,
                UIid: 'connection_322190635448',
                output_custom_priority: 0,
                input_custom_priority: 0,
                type: 'connection',
                isValidated: false,
              },
            },
            ElementData
          )
        )
      );
    } else if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED].includes(status) || [ACTIONS.CLOSE].includes(action)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false);
      dispatch(elementActions.removeElement({ id: 'Input_Resources_19830564551' }));
      dispatch(elementActions.removeElement({ id: 'process_492590947697' }));
      dispatch(elementActions.removeElement({ id: 'connection_322190635448' }));
      if (fac.facilityName === '') {
        dispatch(toggleActions.toggleFacForm(true));
      }
    }

    if (action === 'close') {
      setRunTour(false);
      dispatch(elementActions.removeElement({ id: 'Input_Resources_19830564551' }));
      dispatch(elementActions.removeElement({ id: 'process_492590947697' }));
      dispatch(elementActions.removeElement({ id: 'connection_322190635448' }));
      if (fac.facilityName === '') {
        dispatch(toggleActions.toggleFacForm(true));
      }
    }
    console.groupCollapsed(type);

    console.groupEnd();
  };

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <Joyride
          steps={steps}
          callback={handleJoyrideCallback}
          run={runTour}
          continuous={runTour}
          showProgress
          showSkipButton
          spotlightClicks={true}
          styles={{
            options: {
              // modal arrow and background color
              arrowColor: 'rgb(29 30 35)',
              backgroundColor: 'rgb(29 30 35)',
              // page overlay color
              overlayColor: 'rgb(0 0 0 / 80%)',
              //button color
              primaryColor: '#25cf22',

              //text color
              textColor: 'white',

              //width of modal
              width: 500,
              //zindex of modal
              zIndex: 1000,
            },
          }}
        />
        <div id="flow_pane" className="reactflow-wrapper">
          {isFormOpen ? (
            <Popup type="fac" trigger={isFormOpen} setTrigger={setIsFormOpen}>
              <FacilityForm togglePopup={setIsFormOpen} />
            </Popup>
          ) : (
            ''
          )}
          <div id="popupForm" ref={messagesEndRef}>
            <Popup type="element" element={elementType} trigger={isOpen} setTrigger={togglePopup}>
              {!isFormOpen && elementType === 'process' ? (
                <ProcessForm id={elementId} scaling={nL} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType.includes('Output_Resource' || 'Input_Resource' || 'External') ? (
                <ConnectionForm External={ext} scaling={nL} Resources={res} id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'storage' ? (
                <StorageForm Resources={res} scaling={nL} id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'node' ? (
                <NodeForm id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'battery' ? (
                <BatteryForm id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'delaybuffer' ? (
                <DelaybufferForm id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'reshydro' ? (
                <ReshydroForm id={elementId} togglePopup={togglePopup} />
              ) : !isFormOpen && elementType === 'connection' ? (
                <ConnectionForm External={ext} scaling={nL} Resources={res} id={elementId} togglePopup={togglePopup} />
              ) : (
                ' '
              )}
            </Popup>
          </div>
          {isConfigOpen && !isUploadFile ? (
            <Popup type="element" element="Config Form" trigger={isConfigOpen} setTrigger={setIsConfig}>
              <ConfigForm Resources={res} togglePopup={setIsConfig} />
            </Popup>
          ) : !isConfigOpen && isUploadFile ? (
            <Popup trigger={isUploadFile} setTrigger={setIsUploadFile}>
              <UploadFileForm />
            </Popup>
          ) : (
            ''
          )}

          <ReactFlow
            elements={ElementData}
            onConnect={onConnect}
            onElementClick={onElementClick}
            onNodeDragStop={onNodeDragStop}
            connectionLineComponent={ConnectionLine}
            onEdgeUpdate={onEdgeUpdate}
            onLoad={onLoad}
            onDrop={onDrop}
            nodeTypes={NODE_TYPES}
            zoomOnScroll={zoomOnScroll}
            preventScrolling={false}
            onDragOver={onDragOver}
          >
            <Controls>
              <ControlButton id="help-btn" onClick={() => setRunTour(!runTour)}>
                <span className="fa-stack tour" data-tip data-for="help">
                  <i className="fas fa-question-circle fa-lg" id="help"></i>
                </span>
                <ReactTooltip id="help" className="toolBox" place="right" effect="solid">
                  Click here to enable the Tour Guide.
                </ReactTooltip>
              </ControlButton>

              <ControlButton onClick={() => dispatch(toggleActions.setZoomOnScroll())}>
                <span className="fa-stack search" data-tip data-for="scroll">
                  <i className="fas fa-search" id="scroll"></i>
                  {!zoomOnScroll && <i className="fas fa-slash fa-stack-1x fa-flip-vertical Zoom_btn "></i>}
                </span>
                <ReactTooltip id="scroll" className="toolBox" place="right" effect="solid">
                  click to enable "Zoom on Scroll"
                </ReactTooltip>
              </ControlButton>
            </Controls>
          </ReactFlow>
        </div>

        <Sidebar />

        <MiniMap />
      </ReactFlowProvider>
    </div>
  );
};
export default DragnDrop;
