const config = {
  apiGatewayDev: {
    API_KEY: '85ygv7Wa8q1mhkR1SzvIv2SWcy41PjMtai5XMQsx',
    API_HOST: 'https://pearl-api-dev.ai.fmgaws.cloud',
  },
  apiGatewayTest: {
    API_KEY: 'y403ic9bqxEoe4dUa4BR2QgsTjuasq4RdvjjKZe0',
    API_HOST: 'https://pearl-api-test.ai.fmgaws.cloud',
  },
  apiGatewayProd: {
    API_KEY: 'WN7XZ4LgFN5HwPgRCkTg451h0g0pINqa2OdbHMQE',
    API_HOST: 'https://pearl-api.ai.fmgaws.cloud',
  },
};

export default config;
