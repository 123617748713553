import { elementActions } from './ElementSlice';

const deleteaction = () => {
  return async (dispatch) => {
    dispatch(elementActions.deleteFacilityHandler(true));
    return await Promise.resolve();
  };
};

export default deleteaction;
