import './Popup.css';
import Styles from '../ElementForms/Styles';
import CustomTooltip from 'components/Utils/CustomTooltip';
import Notification from 'components/Navigation_bar/Notification';
import { toggleActions } from 'components/Store/TogglePopup';
import { useDispatch } from 'react-redux';

const Popup = (props) => {
  const ElementClass = props.element === 'Config Form' ? 'popup_config' : 'popup-inner';
  const name = props.element === 'Config Form' ? 'Calculation' : props.element;
  const dispatch = useDispatch();
  return props.trigger ? (
    <div className="popup">
      {props.type === 'element' ? (
        <div className={ElementClass}>
          <div className="button_container">
            <p className="Formtitle"> {name} parameters:</p>
            <button
              className="close-btn"
              id="close_btn"
              onClick={() => {
                props.setTrigger(false);
              }}
            >
              <i className="far fa-window-close fa-2x icon"> </i>
            </button>
          </div>

          <div className="inner">
            <Styles> {props.children} </Styles>
          </div>
        </div>
      ) : props.type === 'fac' ? (
        <div className="popup-Fac">
          <div className="button_container">
            <p className="facilityTitle"> Enter the high level details of your Facility here:</p>

            <button
              className="close-btn"
              id="close_btn"
              onClick={() => {
                props.setTrigger(false);
              }}
            >
              <i className="far fa-window-close fa-2x icon"> </i>
            </button>
          </div>
          <div className="inner">
            <Styles> {props.children} </Styles>
          </div>
        </div>
      ) : (
        <div className="popup-upload">
          <div className="button_container">
            {props.type !== 'facupload' ? (
              <span className="uploadTitle">
                <CustomTooltip
                  id="upl"
                  place="left"
                  text="This form can be used to add a new resource type, scaling curve, or input/output data to PEARL. Once added, the new measurement units, curve, or time series will be available for all users. Make sure to upload at least one day prior to use as it will take up to one day to be available."
                />
                Upload Reference Data: &nbsp;
              </span>
            ) : (
              <span className="uploadTitle">
                <CustomTooltip
                  id="upl"
                  place="left"
                  text="This feature allows you to import another user's facility model. It requires the other user to export the facility first and share the export file"
                />
                Import Facility: &nbsp;
              </span>
            )}
            <button
              className="close-btn"
              id="close_btn"
              onClick={() => {
                dispatch(toggleActions.setIsNotif(false));
                dispatch(toggleActions.setIsWarnNotif(false));

                props.setTrigger(false);
              }}
            >
              <i className="far fa-window-close fa-2x icon"> </i>
            </button>
          </div>
          <div className="inner">
            {props.type !== 'facupload' ? <Notification /> : <div> </div>}

            <Styles> {props.children} </Styles>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div> </div>
  );
};

export default Popup;
