export function formatPercentage(input) {
  if (input === 0 || input < 0) {
    return 0;
  }
  return input ? parseFloat((input * 100).toFixed(2)) : '';
}

export function normalizePercentage(val) {
  if (val === 0 || val < 0) {
    return 0;
  }
  return val ? val / 100 : '';
}

export function normalizePercentageConfig(val) {
  if (val === 0 || val < 0) {
    return 0;
  }
  return val ? (val / 100).toString() : '';
}

export function normalizeSingleQuote(value) {
  return value ? value.replace(/'/g, '`') : value;
}

export function formatSingleQuote(value) {
  return value ? value.replace(/`/g, "'") : value;
}

export const lonlatHandler = (array) => {
  var lonInd;
  var latInd;
  var checkLon = true;

  lonInd = 5;
  latInd = 4;

  if (lonInd !== '' && latInd !== '') {
    for (var k = 1; k < array.length; k++) {
      if (
        parseFloat(array[k][lonInd]) >= -180 &&
        parseFloat(array[k][lonInd]) <= 180 &&
        parseFloat(array[k][latInd]) >= -90 &&
        parseFloat(array[k][latInd]) <= 90
      ) {
        checkLon = true;
      } else {
        checkLon = false;
      }
    }
  }
  return checkLon;
};

export const nonNegativeHandler = (array, filetype) => {
  let checkneg = true;
  if (filetype === 'scalingcurve') {
    for (var j = 1; j < array.length; j++) {
      for (var i = 0; i < array[0].length; i++) {
        if (parseFloat(array[j][i]) < 0 || parseFloat(array[j][i]) > 1) {
          checkneg = false;
        }
      }
    }
  } else if (filetype === 'timeseries') {
    for (var k = 1; k < array.length; k++) {
      if (parseFloat(array[k][9]) < 0 || parseFloat(array[k][9]) > 1) {
        checkneg = false;
      }
    }
  } else if (filetype === 'resource') {
    for (var p = 1; p < array.length; p++) {
      if (parseFloat(array[p][2]) < 0) {
        checkneg = false;
      }
    }
  }
  console.log('neg check', checkneg);

  return checkneg;
};

export const emptycheckHandler = (array) => {
  let check = true;

  for (var j = 1; j < array.length; j++) {
    for (var i = 0; i < array[0].length; i++) {
      if (array[j][i] === '') {
        console.log('here');
        check = false;
      }
    }
  }

  return check;
};

export const check = (arrayofArrays, headers) => {
  let sortedHeader = [...headers];
  let valid = false;

  if (
    (arrayofArrays[0].length === sortedHeader.length &&
      sortedHeader.length &&
      sortedHeader
        .sort()
        .every(
          (element, index) => arrayofArrays[0].sort()[index].replace('\r', '').toString() === element.toString()
        )) === true
  ) {
    valid = true;
  }
  return valid;
};

export function findObject(arr) {
  let element = '';
  for (var j = 1; j < arr.length; j++) {
    element = arr[j].find((element) => element.toString().includes(','));
  }
  return typeof element === 'undefined' ? false : true;
}
