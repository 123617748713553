import { createSlice } from '@reduxjs/toolkit';

const TogglePopup = createSlice({
  name: 'toggleForm',
  initialState: {
    formIsVisible: false,
    isOpen: false,
    isHome: false,
    isNewFac: false,
    isAssumptions: false,
    sendData: false,
    FieldRequire: false,
    isSuccess: false,
    isFacilityLoaded: false,
    isLoaded: false,
    isSaveDisabled: true,
    isSaveAS: false,
    isNotif: false,
    isDialog: false,
    isLoadRun: false,
    isConfigOpen: false,
    zoomOnScroll: true,
    isUploadFile: false,
    isUploadFac: false,
    runTour: false,
    isWarnNotif: false,
  },
  reducers: {
    setIsWarnNotif(state, action) {
      state.isWarnNotif = action.payload;
    },
    setTour(state, action) {
      state.runTour = action.payload;
    },
    uploadFile(state) {
      state.isUploadFile = !state.isUploadFile;
    },
    uploadFac(state) {
      state.isUploadFac = !state.isUploadFac;
    },
    setZoomOnScroll(state) {
      state.zoomOnScroll = !state.zoomOnScroll;
    },
    setIsConfigOpen(state) {
      state.isConfigOpen = !state.isConfigOpen;
    },
    getIsLoadRun(state, action) {
      state.isLoadRun = action.payload;
    },
    setIsNotif(state, action) {
      state.isNotif = action.payload;
    },
    setIsDialog(state, action) {
      state.isDialog = action.payload;
    },
    setIsSaveDisabled(state, action) {
      state.isSaveDisabled = action.payload;
    },
    setIsSaveAS(state, action) {
      state.isSaveAS = action.payload;
    },
    setSendData(state, action) {
      state.sendData = action.payload;
    },
    setIsNewFac(state) {
      state.isNewFac = !state.isNewFac;
    },
    setIsLoaded(state) {
      state.isLoaded = !state.isLoaded;
    },
    setIsSucess(state, action) {
      state.isSuccess = action.payload;
    },
    toggleFacForm(state, action) {
      state.formIsVisible = action.payload;
    },
    toggleElement(state) {
      state.isOpen = !state.isOpen;
    },
    toggleHome(state) {
      state.isHome = !state.isHome;
    },
    setFacilityLoaded(state, action) {
      state.isFacilityLoaded = action.payload;
    },
    toggleAssumptions(state) {
      state.isAssumptions = !state.isAssumptions;
    },

    setFieldRequire(state, action) {
      state.FieldRequire = action.payload;
    },
  },
});

export const toggleActions = TogglePopup.actions;

export default TogglePopup;
