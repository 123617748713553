import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { LogLevel } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const msalConfig = {
  auth: {
    clientId: 'e663505d-7d3b-48a1-89b2-bd1bc272cf8c',
    authority: 'https://login.microsoftonline.com/143a7396-a856-47d7-8e31-62990b5bacd0',
    knownAuthorities: [],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          console.debug(message);
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: ['openid', 'profile', 'User.Read'],
  // scopes: ["e663505d-7d3b-48a1-89b2-bd1bc272cf8c/.default", "openid", "profile", "User.Read"],
};

export const authenticationParametersGraph = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

let tmp_authProvider = {};

if (window.Cypress) {
  tmp_authProvider.getIdToken = function () {
    return {
      idToken: '23872387',
      state: 'dgsdfgfgf3847sfgsdgds89347dcvfhujdxbchjdxbcusweydrf89wcndjxc984yer98yhchiufas',
    };
  };

  tmp_authProvider.getAccountInfo = function () {
    return { account: { name: 'Jane Doe', userName: 'jane.doe@email.com' } };
  };
} else {
  tmp_authProvider = new MsalAuthProvider(msalConfig, authenticationParameters, options);
}

export const authProvider = tmp_authProvider;
