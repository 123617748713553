import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { Values } from "redux-form-website-template";
import store from './components/Store/store';
import './index.css';

import HomePage from './components/Home/HomePage';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { authProvider } from './authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { createBrowserHistory } from 'history';
import { ConfirmProvider } from 'material-ui-confirm';

const history = createBrowserHistory();

let app = document.getElementById('root');
if (app) {
  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
}

if (window.Cypress) {
  ReactDOM.render(
    <ConfirmProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/app" exact>
              return <App /> ;
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </ConfirmProvider>,
    app
  );
} else {
  ReactDOM.render(
    <ConfirmProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
                {({ authenticationState }) => {
                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                      return <HomePage />;
                    default:
                      return <div class="label"> Authenticating... </div>;
                  }
                }}
              </AzureAD>
            </Route>
            <Route path="/app" exact>
              <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
                {({ authenticationState }) => {
                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                      return <App />;
                    default:
                      return <div class="label"> Authenticating... </div>;
                  }
                }}
              </AzureAD>
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
    </ConfirmProvider>,
    app
  );
}
