import { elementActions } from './ElementSlice';

const myActionCreator = () => {
  return (dispatch) => {
    dispatch(elementActions.modifyData());
    return Promise.resolve();
  };
};

export default myActionCreator;
