import './Assumptions.css';
import { toggleActions } from 'components/Store/TogglePopup';
import { useDispatch } from 'react-redux';
import Assumptions from '../Data/Assumptions';

const Info = () => {
  const dispatch = useDispatch();

  const togglePopup = () => {
    dispatch(toggleActions.toggleAssumptions());
  };

  return (
    <div className="Assumptions">
      <div>
        <button className="close-btn" onClick={togglePopup}>
          <i className="far fa-window-close fa-2x icon"> </i>
        </button>
      </div>

      {Assumptions.map((data) => (
        <div key={data.id}>
          <h1> {data.heading} </h1>
          <h3>{data.description}</h3>
          <ul>
            <b>{data.heading2} </b>
          </ul>
          {data.assumptions.map((desc) => (
            <li key={desc.id}> {desc.description}</li>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Info;
