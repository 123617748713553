import React from 'react';
import Navbar from './components/Navigation_bar/Navbar';
import './App.css';
import DragnDrop from './components/DnD_UI/DragnDrop';

const App = () => {
  return (
    <div>
      <div className="topnav">
        <Navbar />
      </div>{' '}
      <DragnDrop />
    </div>
  );
};

export default App;
