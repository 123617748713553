const axios = require('axios');
const config = require('../../config');

const UploadFileSuccess = async (check) => {
  let message = { error: false, success: false };
  let apiGateway = {};

  switch (window.location.hostname) {
    case 'pearl.ai.fmgaws.cloud': {
      // prod
      apiGateway = {
        API_KEY: config.default.apiGatewayProd.API_KEY,
        API_HOST: config.default.apiGatewayProd.API_HOST,
      };
      break;
    }
    case 'pearl-test.ai.fmgaws.cloud': {
      // test
      apiGateway = {
        API_KEY: config.default.apiGatewayTest.API_KEY,
        API_HOST: config.default.apiGatewayTest.API_HOST,
      };
      break;
    }
    default: {
      // dev
      apiGateway = {
        API_KEY: config.default.apiGatewayDev.API_KEY,
        API_HOST: config.default.apiGatewayDev.API_HOST,
      };
    }
  }
  console.log('check', check);
  try {
    const ret = await axios.post(apiGateway.API_HOST + '/api/csv-upload-check', check, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-api-key': apiGateway.API_KEY,
      },
    });
    console.log('Ret', ret, ret.status);
    if (ret.data.id && ret.data.id !== '') {
      message.success = true;
    }
    return message;
  } catch (err) {
    console.error(err);
    message.error = true;
    return message;
  }
};

export default UploadFileSuccess;
