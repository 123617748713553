import React, { useRef, useCallback, useEffect } from 'react';
import './Buttons.css';
import FacilityNameForm from './FacilityNameForm';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import { toggleActions } from 'components/Store/TogglePopup';
import { ReformatData } from 'components/SaveData/ReformatData';
import ReactTooltip from 'react-tooltip';
import { NotificationActions } from 'components/Store/SendNotifications';
import { useConfirm } from 'material-ui-confirm';
import myActionCreator from 'components/Store/send-action';
import deleteaction from 'components/Store/delete-action';
import SendData from 'components/SaveData/SendData';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/ElementForms/Styles';
import { ConfigActions } from 'components/Store/ConfigSlice';
import { v4 as uuidv4 } from 'uuid';

const Buttons = () => {
  let history = useHistory();
  const confirm = useConfirm();
  const classes = useStyles();
  const inputFile = useRef(null);
  const isSendData = useSelector((state) => state.toggleForm.sendData);
  const isSuccess = useSelector((state) => state.toggleForm.isSuccess);
  const isFacilityLoaded = useSelector((state) => state.toggleForm.isFacilityLoaded);
  const isFacilityValidated = useSelector((state) => state.comp.isFacilityValidated);
  const isSaveDisabled = useSelector((state) => state.toggleForm.isSaveDisabled);
  const isLoadRun = useSelector((state) => state.toggleForm.isLoadRun);
  const dispatch = useDispatch();

  const ElementData = useSelector((state) => state.comp);

  // function to save the facility data
  const sendFacData = useCallback(
    async (isrunTrue) => {
      await dispatch(myActionCreator()).then(async () => {
        const list = await ReformatData(ElementData);
        dispatch(elementActions.setIsFacilityValidated(true));
        dispatch(elementActions.setIsPrioritySet(true));
        if (list.setIsSucess) {
          if (isrunTrue) {
            dispatch(toggleActions.setIsConfigOpen());
          } else {
            dispatch(toggleActions.setIsNotif(true));
            dispatch(
              NotificationActions.showNotification({
                status: 'success',
                title: 'Send and Verified',
                message: 'Facility was successfully validated and saved. It is ready to run with C++.',
              })
            );
          }
        } else {
          dispatch(elementActions.setIsFacilityValidated(false));
        }

        if (list.changeColor) {
          list.changeColor.map((el) => dispatch(elementActions.changeElColour(el)));
        }
        dispatch(toggleActions.setIsSucess(list.setIsSucess));

        if (list.success && !list.setIsSucess) {
          dispatch(toggleActions.setIsNotif(true));
          list.success &&
            dispatch(
              NotificationActions.showNotification({
                status: 'success',
                title: 'Facility saved',
                message: 'Facility is successfully saved.',
              })
            );

          dispatch(
            NotificationActions.showNotification({
              status: 'warning',
              title: 'warning',
              message: list.warnings,
            })
          );
        } else {
          list.error &&
            dispatch(
              NotificationActions.showNotification({
                status: 'error',
                title: 'error occurred!',
                message: list.error,
              })
            );
        }
      });
    },
    [ElementData, dispatch]
  );

  const changeFacilityID = () => {
    confirm({ description: 'Do you want to create a new facility?', confirmationText: 'Create new' })
      .then(() => {
        setTimeout(() => {
          dispatch(elementActions.addFacilityId(Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))));
          dispatch(elementActions.updateElementIds());
        }, 1000);

        dispatch(elementActions.updateFacilityData('saveAs'));
        dispatch(toggleActions.toggleFacForm(true));
        dispatch(toggleActions.setIsSaveAS(true));
      })
      .catch(() => console.log('Deletion cancelled.'));
  };

  useEffect(() => {
    isSendData && sendFacData(false);
    dispatch(toggleActions.setSendData(false));
  }, [isSendData, sendFacData, dispatch]);

  const saveDataHandler = (isRunTrue) => {
    console.log('isRunTrue: saveDataHandler', isRunTrue);

    if (ElementData.facilityName === '') {
      alert('Facility Name Required!');
      dispatch(toggleActions.toggleFacForm(true));

      return;
    } else if (isFacilityLoaded === true && isRunTrue === false) {
      confirm({
        description:
          "Saving will overwrite the loaded facility. Please choose 'Save As' to avoid losing data or click 'OK' to continue.",
      })
        .then(() => sendFacData(false))
        .catch(() => console.log('Deletion cancelled.'));
    } else if (isRunTrue) {
      sendFacData(true);
    } else {
      sendFacData(false);
    }
  };

  const SaveButton = !isLoadRun && isSaveDisabled && !isFacilityLoaded;
  const runButton = !isSuccess && !isFacilityValidated;

  const runScriptHandler = () => {
    dispatch(ConfigActions.updateConfigID(uuidv4()));
    saveDataHandler(true);
  };

  const ResetElementFromStore = (values) => {
    values.preventDefault();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const uploadFileHandler = () => {
    dispatch(toggleActions.setIsNotif(true));

    dispatch(
      NotificationActions.showNotification({
        status: 'error',
        title: 'error',
        message:
          'Please do not perform any activities in this browser tab after pressing upload, otherwise the uploads will fail. Please wait until the data upload progress reaches 100%. Once all data is uploaded this popup will close automatically',
      })
    );
    dispatch(toggleActions.uploadFile());
  };

  const deleteFacilityHandler = () => {
    confirm({ description: 'Do you want to delete this facility?', confirmationText: 'Delete' })
      .then(() => {
        dispatch(deleteaction()).then(() => {
          let newData = { ...ElementData, deleteFacility: true };
          setTimeout(async () => {
            await SendData(newData);
          }, 1000);
        });
        history.goBack();
        dispatch(toggleActions.setIsNotif(true));

        dispatch(
          NotificationActions.showNotification({
            status: 'success',
            title: 'Facility deleted',
            message: 'Facility is successfully deleted.',
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  function toBinary(string) {
    const codeUnits = new Uint16Array(string.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = string.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }

  const exportToJson = (e) => {
    e.preventDefault();
    let filename = ElementData.facilityName;
    downloadFile({
      data: toBinary(JSON.stringify({ ...ElementData, createdAt: new Date().toLocaleString() + '' })),
      fileName: filename + '.prl',
      fileType: 'text',
    });
  };

  return (
    <div className="input-wrapper">
      <FacilityNameForm />
      {/* <input className="upload" ref={inputFile} type="file" style={{ display: 'none' }} /> */}
      <button type="file" onClick={uploadFileHandler} className={`${classes.upload} load_btn`} id="load_btn">
        Upload Reference Data
      </button>
      <input className="upload" ref={inputFile} type="file" style={{ display: 'none' }} />
      <button type="button" className={`${classes.root} reset_btn`} onClick={ResetElementFromStore} id="reset_btn">
        Reset
      </button>
      <div data-tip data-for="save_data">
        <button
          id="save_Fac_data"
          type="button"
          disabled={SaveButton}
          data-type="warning"
          className={`${classes.root} save`}
          onClick={() => {
            saveDataHandler(false);
          }}
        >
          Save
        </button>
      </div>
      <ReactTooltip id="save_data" place="bottom" className="toolBox_warn" effect="solid">
        Saving will overwrite the pre-loaded facility
      </ReactTooltip>
      <div data-tip data-for="saveAS_data">
        <button
          type="button"
          id="saveAS"
          className={`${classes.root} saveAS_data`}
          disabled={isSaveDisabled && !isFacilityLoaded}
          data-tip
          data-for="saveAS_data"
          onClick={changeFacilityID}
        >
          Save As
        </button>
      </div>
      <ReactTooltip id="saveAS_data" className="toolBox" place="bottom" effect="solid">
        This won't overwrite the pre-loaded facility
      </ReactTooltip>
      <button
        type="button"
        disabled={SaveButton}
        className={`${classes.del} Delete`}
        onClick={deleteFacilityHandler}
        id="delete_fac"
      >
        Delete Facility
      </button>
      <div>
        <button
          type="button"
          disabled={SaveButton}
          className={`${classes.root} save`}
          onClick={exportToJson}
          data-tip
          data-for="export_data"
        >
          Export Facility
        </button>
        <ReactTooltip id="export_data" className="toolBox" place="bottom" effect="solid">
          Downloads the facility file
        </ReactTooltip>
      </div>

      <div data-tip data-for="Run">
        <button
          id="Run"
          type="button"
          className={`${classes.root} run`}
          disabled={runButton}
          onClick={runScriptHandler}
        >
          Run C++
        </button>
      </div>
      {runButton ? (
        <ReactTooltip id="Run" className="toolBox_warn" place="bottom" effect="solid">
          You must save a valid facility first.
        </ReactTooltip>
      ) : (
        <ReactTooltip id="Run" className="toolBox" place="bottom" effect="solid">
          Set calculation parameters.
        </ReactTooltip>
      )}
    </div>
  );
};

export default Buttons;
