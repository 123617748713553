import { authProvider } from '../../authProvider';
// import * as req from 'express';

const axios = require('axios');
const config = require('../../config');

const SendData = async (newFac) => {
  console.log('data', JSON.stringify(newFac, null, 2));

  let message = { error: false, success: false };
  const token = authProvider.getIdToken();
  const accountInfo = authProvider.getAccountInfo();
  const account = accountInfo.account;
  const userName = account.userName;

  console.log('token', token);
  console.log('userName', userName);

  let data1 = JSON.stringify(newFac);

  // console.log('data', JSON.stringify(newFac, null, 2));
  let apiGateway = {};
  // console.log('config', config);
  console.log(window.location.hostname);
  switch (window.location.hostname) {
    case 'pearl.ai.fmgaws.cloud': {
      // prod
      apiGateway = {
        API_KEY: config.default.apiGatewayProd.API_KEY,
        API_HOST: config.default.apiGatewayProd.API_HOST,
      };
      break;
    }
    case 'pearl-test.ai.fmgaws.cloud': {
      // test
      apiGateway = {
        API_KEY: config.default.apiGatewayTest.API_KEY,
        API_HOST: config.default.apiGatewayTest.API_HOST,
      };
      break;
    }
    default: {
      // dev
      apiGateway = {
        API_KEY: config.default.apiGatewayDev.API_KEY,
        API_HOST: config.default.apiGatewayDev.API_HOST,
      };
    }
  }

  try {
    axios
      .post(
        apiGateway.API_HOST + '/facility',
        { data: data1, username: userName },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'x-api-key': apiGateway.API_KEY,
          },
        }
      )
      .then((res) => {
        console.log(`statusCode: ${res.status}`);
        console.log('respond', res);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error response', error.response.data);
          message.error = error.response.data;
        }
      });
    message.success = true;
  } catch (err) {
    console.error(err);
    message.error = err;
  }

  return message;
};

export default SendData;
