const Electrolyser = (newElement) => {
  let temp = {
    id: newElement.id,
    type: newElement.type,
    position: newElement.position,
    className: newElement.className,
    sourcePosition: newElement.sourcePosition,
    targetPosition: newElement.targetPosition,
    data: {
      id: newElement.data.id,
      UIid: newElement.id,
      type: newElement.data.type,
      name: newElement.data.label,
      color: newElement.data.color,
      label: newElement.data.label,
      isValidated: newElement.data.isValidated,
      reward_critical_path: true,
      CAPEX_nl_id: 1,
      OPEX_nl_id: 1,
      ramp_up_limit: 1,
      ramp_down_limit: 1,
      default_initial_status: 0.5,
      min_throttle: 0,
      shut_in_duration_threshold: 0,
      fast_restart_steps: 0,
      slow_restart_steps: 0,
      group: 'No group',
      elNumber: newElement.data.elNumber,
    },
    style: {
      backgroundColor: newElement.data.color,
    },
  };
  return temp;
};

export default Electrolyser;
