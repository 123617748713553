// import { DataGrid } from '@mui/x-data-grid';
import './CreateTable.css';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 50,
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 180,
  },
  { field: 'Longitude', headerName: 'Longitude', width: 170 },
  {
    field: 'Latitude',
    headerName: 'Latitude',
    width: 170,
  },
  { field: 'Type', headerName: 'Type', width: 130 },
  { field: 'Country', headerName: 'Country', width: 160 },

  { field: 'Start Date', headerName: 'Start Date', width: 200 },
  { field: 'End Date', headerName: 'End Date', width: 200 },
  { field: 'Description', headerName: 'Description', width: 300 },
];

const CreateTable = (props) => {
  return (
    <div style={{ height: 300, width: '100%', colour: 'white' }}>
      <DataGrid
        rows={props.data}
        rowHeight={20}
        columns={columns}
        pageSize={7}
        rowsPerPageOptions={[7]}
        disableSelectionOnClick
      />
    </div>
  );
};

export default CreateTable;
