import React, { memo, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useUpdateNodeInternals } from 'react-flow-renderer';
import './handle.css';

const MultiInput = memo(({ data }: any) => {
  // console.log('multi', data);
  const nodeRef = useRef<any>();

  var Nodeid = data.UIid;
  const updateNodeInternals = useUpdateNodeInternals();

  var No_of_Inputs = 5;
  var No_of_Output = 5;

  function range(len: number) {
    return Array(len - 1 + 1)
      .fill(1)
      .map((_, idx) => 1 + idx);
  }
  let targetArray = range(No_of_Inputs);
  let sourceArray = range(No_of_Output);

  const dimensions = useMemo(() => {
    return { width: 100, height: 70 };
  }, []);

  const [nodelabel, setNodeLabel] = useState('');
  const [nodeGroup, setNodeGroup] = useState('');

  useEffect(() => {
    setNodeLabel(data.label);

    if (data.group && data.group !== 'No group') {
      setNodeGroup(data.group.split(' ')[1]);
    } else if (data.group && data.group === 'No group') {
      setNodeGroup('');
    }
    updateNodeInternals(Nodeid);
  }, [data, Nodeid, updateNodeInternals]);

  const positionHandle = useCallback(
    (index) => {
      if (index === 1) {
        return 15;
      } else if (index !== 2) {
        return (dimensions.height / 6) * index;
      } else if (index === 2) {
        return dimensions.height;
      }
    },
    [dimensions]
  );

  const targetHandles = useMemo(
    () =>
      targetArray.map((x, i) => {
        const handleId = `${data.UIid.split('_')[0]}_left${i + 1}`.toString();
        return (
          <Handle
            key={handleId}
            type="target"
            position={Position.Left}
            className="react-flow_handle_multiple"
            id={handleId}
            style={{ top: positionHandle(i + 1) }}
          />
        );
      }),
    [targetArray, positionHandle, data.UIid]
  );

  const sourceHandles = useMemo(
    () =>
      sourceArray.map((x, i) => {
        const handleId = `${data.UIid.split('_')[0]}_right${i + 1}`.toString();
        // var isConnectable = true;

        // if (i === 2) {
        //   isConnectable = false;
        // }
        // console.log(i, isConnectable);
        return (
          <Handle
            key={handleId}
            type="source"
            position={Position.Right}
            className="react-flow_handle_multiple"
            id={handleId}
            style={{ top: positionHandle(i + 1) }}
          />
        );
      }),

    [sourceArray, positionHandle, data.UIid]
  );

  return (
    <div ref={nodeRef}>
      {targetHandles}
      <div className="cont_name">
        <span id="node_name"> {nodelabel} </span>
        <span className="node_group">{nodeGroup} </span>
      </div>
      {sourceHandles}
    </div>
  );
});

export default memo(MultiInput);
