import React, { DragEvent } from 'react';
import './FacElement.css';

const onDragStart = (event: DragEvent, NodeData: any) => {
  event.dataTransfer.setData('application/reactflow', NodeData);
  event.dataTransfer.effectAllowed = 'move';
};

const itemStyle = {
  padding: '30px',
  marginTop: '50px',
  borderRadius: '3px',
  width: '13rem',
  boxShadow: 'inset 0px 0px 7px #19262e',
  fontWeight: 40,
  backgroundColor: 'rgb(219, 219, 219)',
};

const FacElement = (props: any) => {
  const NodeData = { type: props.type, icon: props.icon, ElementName: props.ElementName };
  return (
    <div
      // className="react-flow__node-process"
      id={'fac_' + props.ElementName.split(' ')[0]}
      className={'fac_' + props.ElementName.split(' ')[0]}
      onDragStart={(event: DragEvent) => onDragStart(event, JSON.stringify(NodeData, null, 2))}
      draggable
      style={itemStyle}
    >
      <span className={props.icon} />
      &nbsp;{props.ElementName}
    </div>
  );
};

export default FacElement;
