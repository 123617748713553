import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import account from './account';
import ElementSlice from './ElementSlice';
import TogglePopup from './TogglePopup';
import rootReducer from './authentication';
import SendNotifications from './SendNotifications';
import ConfigSlice from './ConfigSlice';
import myActionCreator from './send-action';
import { composeWithDevTools } from 'redux-devtools-extension';

const thunkMiddleware = require('redux-thunk').default;
const reducer = combineReducers({
  rootReducer,
  account,
  myActionCreator,

  form: reduxFormReducer, // mounted under "form"
  comp: ElementSlice.reducer,
  config: ConfigSlice.reducer,
  toggleForm: TogglePopup.reducer,
  Notif: SendNotifications.reducer,
});

const store = window.devToolsExtension
  ? createStore(
      reducer,
      compose(
        applyMiddleware(thunkMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    )
  : createStore(reducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));

store.subscribe(() => {
  window.localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});
export default store;
