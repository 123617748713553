import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import { ColorPicker } from 'material-ui-color';
import { Grid, Box } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { normalizePercentage, formatPercentage } from '../Utils/utils';
import CustomTooltip from '../Utils/CustomTooltip';
import NumberFormat from 'react-number-format';

const validate = (values) => {
  const errors = {};
  if (values.low_boundary_capacity) {
    if (values.high_boundary_capacity < values.low_boundary_capacity) {
      errors.high_boundary_capacity = 'should be more than low boundary Capacity';
    }
  }
  if (values.low_boundary_OPEX) {
    if (values.high_boundary_OPEX < values.low_boundary_OPEX) {
      errors.high_boundary_OPEX = 'should be more than low boundary';
    }
  }

  if (values.low_boundary_CAPEX) {
    if (values.high_boundary_CAPEX < values.low_boundary_CAPEX) {
      errors.high_boundary_CAPEX = 'should be more than low boundary Capex';
    }
  }

  return errors;
};

const CurrencyFormat = ({
  other,
  prefix,
  id,
  placeholder,
  required,
  name,
  label,
  min,
  unit,
  ref,
  input,
  meta: { touched, error, warning },
}) => (
  <>
    <label>
      {label}
      {prefix}
    </label>
    <NumberFormat
      {...other}
      {...input}
      required={required}
      id={id}
      name={name}
      value={input.value}
      placeholder={placeholder}
      allowNegative={false}
      thousandSeparator
      getInputRef={ref}
      decimalScale={2}
      allowEmptyFormatting={true}
      isNumericString
    />{' '}
    <p> {unit} </p>
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </>
);

let StorageForm = (props) => {
  const letters = ['No group'];
  for (let i = 65; i <= 90; i++) {
    letters.push('Group ' + String.fromCharCode(i));
  }

  const { handleSubmit, load, pristine, reset, submitting } = props;
  const dispatch = useDispatch();
  const ElementData = useSelector((state) => state.comp.components);

  let data = ElementData.filter((element) => element.id === props.id);

  const connection = ElementData.filter((e) => e.data.type === 'connection' && e.target === data[0].data.UIid);

  var unit = () => {
    if (connection.length !== 0 && connection[0].data?.resource_id) {
      let res = props.Resources.filter((data) => data.id.toString() === connection[0].data?.resource_id.toString()).map(
        (d) => {
          return d.Units;
        }
      );
      return res;
    } else return '';
  };

  const newObj = Object.assign({ default_initial_status: 0.5, CAPEX_nl_id: 1, OPEX_nl_id: 1 }, data[0].data);
  const [color, setColor] = useState(newObj.color);

  useEffect(() => {
    load(newObj);
  }, [newObj, load]);

  const removeElementFromStore = (id) => {
    console.log('is', id);
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
  };

  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };

  const [isRequired, SetIsRequired] = useState(true);

  const saveForLater = () => {
    SetIsRequired(false);
  };

  const setValidation = () => {
    dispatch(elementActions.SetFormValidatation(props.id));
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        values = { ...values, color: color };

        if (isRequired === true) {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }
        updateData(values);
        props.togglePopup();
      })}
    >
      <div>
        <Grid container spacing={1} columns={16}>
          <Grid item xs={5}>
            <label>Name:</label>
            <Field
              component="input"
              name="label"
              type="text"
              id="FormName"
              required={isRequired}
              className="opt_name"
              placeholder="Enter the Name"
            />
          </Grid>
          <Grid item xs={4}>
            <div>
              <CustomTooltip
                id="group"
                text="Plants & external connections in the same group will scale up & down together /
              Select the same group letter [A-Z] to place different components into the same group or leave /
              'no group' if you don't want link components."
              />
              <label>Group: </label>
              <Field name="group" component="select" value="No group" required={isRequired} id="group" className="opt">
                {letters.map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </Field>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="color-picker">
              <label>Element color: </label>
              <ColorPicker
                hideTextfield
                name="color"
                className="opt_input"
                value={color}
                onChange={(e) => setColor(e.css.backgroundColor)}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <label>Enter operational parameters:</label>
      <div className="form_container2">
        <div className="column">
          <div>
            <CustomTooltip id="Reward" text="How full the storage plant is at the beginning of the model run." />
            <label>
              Default initial: &nbsp;
              <Field
                name="default_initial_status"
                component="input"
                type="number"
                step="0.5"
                required={isRequired}
                id="default_initial_status"
                className="opt_input"
                format={formatPercentage}
                normalize={normalizePercentage}
                min="0"
                max="100"
                parse={(value) => (value ? parseFloat(value) : '')}
              />
              %
            </label>
          </div>
        </div>
      </div>
      <label>Enter limits for sampling and select scaling curves:</label>
      <div className="form_container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid columns={1}></Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <label>Low Boundary:</label>
            </Grid>
            <Grid item xs={3}>
              <label>High Boundary:</label>
            </Grid>
            <Grid item xs={4}>
              <label>Scaling Curve:</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip
                id="Capacity"
                text="The size of the storage; how many units of resource this storage plant is going to be able to store."
              />

              <label>Capacity</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_capacity"
                className="opt_input"
                component={CurrencyFormat}
                min="0.0"
                id="low_boundary_capacity"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
              />
              <label> {unit()} </label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_capacity"
                className="opt_input"
                component={CurrencyFormat}
                required={isRequired}
                min="0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                placeholder="0.0"
                id="high_boundary_capacity"
              />
              <label> {unit()} </label>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip id="Capex" text="The installation cost for the storage plant capacity." />

              <label>CAPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_CAPEX"
                className="opt_input"
                component={CurrencyFormat}
                min="0.000"
                required={isRequired}
                id="low_boundary_CAPEX"
                unit="USD"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                placeholder="0.0"
                prefix="$"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_CAPEX"
                className="opt_input"
                id="high_boundary_CAPEX"
                placeholder="0.0"
                component={CurrencyFormat}
                min="0.000"
                required={isRequired}
                unit="USD"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                prefix="$"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="CAPEX_nl_id"
                className="dropdown"
                value="Linear"
                parse={(value) => Number(value)}
                required={isRequired}
                id="CAPEX_nl_id"
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip id="OPEX" text="OPEX per year required to run this storage plant at this capacity." />

              <label>OPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                min="0"
                id="low_boundary_OPEX"
                required={isRequired}
                unit="USD/yr"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                placeholder="0.0"
                prefix="$"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                id="high_boundary_OPEX"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                placeholder="0.0"
                min="0.00"
                required={isRequired}
                unit="USD/yr"
                prefix="$"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="OPEX_nl_id"
                className="dropdown"
                value="Linear"
                required={isRequired}
                id="OPEX_nl_id"
                parse={(value) => Number(value)}
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater} data-tip data-for="save_later_form">
          Save Draft
        </button>
        <ReactTooltip id="save_later_form" className="toolBox" place="bottom" effect="solid">
          complete form later
        </ReactTooltip>
        <button type="btn" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
        <button type="Delbutton" id="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
StorageForm = reduxForm({
  form: 'Storage',
  validate,
  // a unique identifier for this form
})(StorageForm);

// // You have to connect() to any reducers that you wish to connect to yourself
StorageForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(StorageForm);

export default StorageForm;
