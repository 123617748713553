import { createSlice } from '@reduxjs/toolkit';

const ConfigSlice = createSlice({
  name: 'config',
  initialState: {
    configId: '',
    facilityId: '',
    TimeZone: '',
    start_time: '2000-02-13 00:00:00 +08:00',
    end_time: '2001-02-13 00:00:00 +08:00',
    discount_rate: '0.1',
    inflation_rate: '0.02',
    years_to_build: '2',
    description: '',
    export_to_database: true,
    n_trajectories_per_loop: '5000',
    n_unsupervised_loops: '30',
    n_top_trajectories: '100',
    min_prod: '20000',
    max_CAPEX: '200000000000',
    baseline_agent_behaviour: [],
    num_years: '1',
    using_rl: false,
    get_traj: false,
  },

  reducers: {
    updateConfigData(state, action) {
      const config = action.payload;
      state.configId = config.configId;
      state.facilityId = config.facilityId;
      state.TimeZone = config.TimeZone.toString();
      state.start_time = config.start_time.toString();
      state.end_time = config.end_time.toString();
      state.num_years = config.num_years.toString();
      state.discount_rate = config.discount_rate.toString();
      state.inflation_rate = config.inflation_rate.toString();
      state.years_to_build = config.years_to_build.toString();
      state.description = config.description.toString();
      state.export_to_database = config.export_to_database;
      state.n_trajectories_per_loop = config.n_trajectories_per_loop.toString();
      state.n_unsupervised_loops = config.n_unsupervised_loops.toString();
      state.n_top_trajectories = config.n_top_trajectories.toString();
      state.min_prod = config.min_prod.toString();
      state.max_CAPEX = config.max_CAPEX.toString();
      state.baseline_agent_behaviour = config.baseline_agent_behaviour.toString();
      state.using_rl = config.using_rl;
      state.get_traj = config.get_traj;
    },

    updateConfigID(state, action) {
      const id = action.payload;
      state.configId = id;
    },
  },
});

export const ConfigActions = ConfigSlice.actions;

export default ConfigSlice;
