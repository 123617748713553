import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import { ColorPicker } from 'material-ui-color';

let NodeForm = (props) => {
  const { handleSubmit, load, pristine, reset, submitting } = props;
  const dispatch = useDispatch();

  const ElementData = useSelector((state) => state.comp.components);
  const facId = useSelector((state) => state.comp.facilityId);

  let data = ElementData.filter((element) => element.id === props.id);
  const newObj = Object.assign({ facility_id: facId }, data[0].data);
  const [color, setColor] = useState(newObj.color);

  useEffect(() => {
    load(newObj);
  }, [newObj, load]);

  const removeElementFromStore = (id) => {
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
  };

  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };

  const [isRequired, SetIsRequired] = useState(true);

  const saveForLater = () => {
    SetIsRequired(false);
  };

  const setValidation = () => {
    dispatch(elementActions.SetFormValidatation(props.id));
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (isRequired === true || values.label !== '') {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }
        values = { ...values, color: color };
        updateData(values);
        props.togglePopup();
      })}
    >
      <div>
        <label>Name:</label>
        <Field
          component="input"
          name="label"
          className="currency"
          type="text"
          id="FormName"
          required={isRequired}
          placeholder="Enter the Name"
        />
        <label>Element color:</label>
        <ColorPicker hideTextfield name="color" value={color} onChange={(e) => setColor(e.css.backgroundColor)} />
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater}>
          Save Draft
        </button>
        <button type="btn" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
        <button type="Delbutton" id="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
NodeForm = reduxForm({
  form: 'nodes',
  // a unique identifier for this form
})(NodeForm);

// // You have to connect() to any reducers that you wish to connect to yourself
NodeForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(NodeForm);

export default NodeForm;
