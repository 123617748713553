const axios = require('axios');
const config = require('../../config');

const UploadFile = async (filetype) => {
  console.log('filetype');

  let apiGateway = {};

  switch (window.location.hostname) {
    case 'pearl.ai.fmgaws.cloud': {
      // prod
      apiGateway = {
        API_KEY: config.default.apiGatewayProd.API_KEY,
        API_HOST: config.default.apiGatewayProd.API_HOST,
      };
      break;
    }
    case 'pearl-test.ai.fmgaws.cloud': {
      // test
      apiGateway = {
        API_KEY: config.default.apiGatewayTest.API_KEY,
        API_HOST: config.default.apiGatewayTest.API_HOST,
      };
      break;
    }
    default: {
      // dev
      apiGateway = {
        API_KEY: config.default.apiGatewayDev.API_KEY,
        API_HOST: config.default.apiGatewayDev.API_HOST,
      };
    }
  }

  try {
    var response = await axios.post(apiGateway.API_HOST + '/upload/csv/' + filetype.fileType, filetype.file, {
      headers: {
        'Content-Type': 'text/csv',
        'x-api-key': apiGateway.API_KEY,
      },
    });
    if (response.data.status) {
      console.log(`statusCode: ${response.status}`);
      console.log('respond', response);
      return true;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default UploadFile;
