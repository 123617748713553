// import { toggleActions } from "components/Store/TogglePopup";
import React, { memo, useState, useEffect } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { useSelector } from 'react-redux';

const ExternalDataHandle = (data: any) => {
  const [nodelabel, setNodeLabel] = useState('');
  const [nodeGroup, setNodeGroup] = useState('');

  const [available, setAvailable] = useState(true);
  const [demand, setDemand] = useState(true);

  const el = useSelector((state: any) => state.comp.components);
  useEffect(() => {
    setNodeLabel(data.data.label);

    if (data.data.group && data.data.group !== 'No group') {
      setNodeGroup(data.data.group.split(' ')[1]);
    } else if (data.data.group && data.data.group === 'No group') {
      setNodeGroup('');
    }

    if (data.data.demand_data_series_id) {
      setAvailable(false);
    } else if (data.data.available_data_series_id) {
      setDemand(false);
    }
  }, [data.data]);

  useEffect(() => {
    const DemandConnection = el.filter((element: any) => element.target === data.data.UIid);
    const AvailableConnection = el.filter((element: any) => element.source === data.data.UIid);

    if (DemandConnection.length !== 0) {
      setAvailable(false);
    } else if (AvailableConnection.length !== 0) {
      setDemand(false);
    }
  }, [el, data.data]);

  const rightID = `${data.id.split('_')[0]}_right`;
  const leftID = `${data.id.split('_')[0]}_left`;

  return (
    <>
      {available && <Handle id={rightID} type="source" position={Position.Right} />}
      &nbsp;
      <div className="cont_name">
        <span id="node_name">{nodelabel} </span>
        <span className="node_group">{nodeGroup} </span>
      </div>
      {demand && <Handle id={leftID} type="target" position={Position.Left} />}
    </>
  );
};

export default memo(ExternalDataHandle);
