import React, { memo, useState, useEffect } from 'react';

import { Handle, Position } from 'react-flow-renderer';

const SingleHandle = (data: any) => {
  const [nodelabel, setNodeLabel] = useState('');
  const [nodeGroup, setNodeGroup] = useState('');

  useEffect(() => {
    setNodeLabel(data.data.label);

    if (data.data.group && data.data.group !== 'No group') {
      setNodeGroup(data.data.group.split(' ')[1]);
    } else if (data.data.group && data.data.group === 'No group') {
      setNodeGroup('');
    }
  }, [data.data]);

  const rightID = `${data.id.split('_')[0]}_right`;
  const leftID = `${data.id.split('_')[0]}_left`;

  return (
    <>
      <Handle id={rightID} type="source" position={Position.Right} />
      &nbsp;
      <div className="cont_name">
        <span id="node_name">{nodelabel} </span>
        <span className="node_group">{nodeGroup} </span>
      </div>
      <Handle id={leftID} type="target" position={Position.Left} />
    </>
  );
};

export default memo(SingleHandle);
