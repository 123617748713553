import { createSlice } from '@reduxjs/toolkit';

const SendNotifications = createSlice({
  name: 'toggleForm',
  initialState: {
    notification: null,
    warningNot: null,
  },
  reducers: {
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
    showWarningNotification(state, action) {
      console.log('action', action);
      state.warningNot = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
    },
  },
});

export const NotificationActions = SendNotifications.actions;

export default SendNotifications;
