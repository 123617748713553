const Assumptions = [
  {
    id: 0,
    heading: 'Assumptions of PEARL',
    description: ' ',
    heading2: 'Modelling tool Assumptions:',
    assumptions: [
      {
        id: '1',
        description:
          'Pearl assesses different plant configurations by simulating every hour of plant operation over a period of time pre-selected in the parameters. It then computes the levelised cost of HHV of the products exported by the model. This is done by considering the amount produced, the CAPEX and OPEX of plants.',
      },
      {
        id: '2',
        description:
          'By default, PEARL assumes each plant is run at its set to maximum allowed speed as defined by how much input resources are available to be consumed and how much downstream demand is available to be received. It does not use the most optimum operation of the plants just yet.',
      },
      {
        id: '3',
        description:
          'PEARL has a simplified model of systems with long ramp up dynamics (such as Haber Bosch processes) that simulates the operation of such plants',
      },
      {
        id: '4',
        description:
          'The model makes no allowance for planned shutdowns due to maintenance periods. I.e. it assumes the plant runs for every hour it has resources available to it.',
      },
      {
        id: '5',
        description:
          'The model uses Time Series Data (hourly solar, wind, etc.) as its input to run simulations. This data is used as is and is not modified by the Pearl system. The model does not adjust environmental data series for future time period simulations. The model uses the series data points as they were in the past for the simulations.',
      },
      {
        id: '6',
        description:
          'Technological advancement is not factored in for future time period simulations. Plant efficiency ratios are assumed to be constant over the simulation time period (20-30years typically). The model is not assuming that plant efficiencies improve in 10- or 20-years’ time.',
      },
      {
        id: '7',
        description:
          'All monetary figures, costs are assumed to be in USD and no conversion or impact to local currencies is considered. USD to local currency exchange rate is not considered by the current model.',
      },
      {
        id: '8',
        description:
          'The model considers CAPEX and OPEX of associated infrastructure for the final calculation of LCO. Associated infrastructure is the infrastructure that is required to operate the facility (e.g. a site office) and/or required to sell the resource produced (e.g. a port or transmission line, pipeline) but not part of the resource-producing facility model itself. Components of “associated” infrastructure are not part of the facility’s graphical model.',
      },
      {
        id: '9',
        description:
          "Net present value calculation assumptions: for process plants the present value of the CAPEX, calculated assuming that the capital costs are all accrued halfway through construction time. For process plant OPEX it is assumed that OPEX costs don't start accruing until 6 months before construction is complete.",
      },
      {
        id: '10',
        description:
          'It’s assumed that resource distribution and combination of the same type of resource always happens through the use of “node/distributor” component and not by simply having two connections with the same type of resource flowing into a plant or coming out of a plant (which would produce incorrect results).',
      },
    ],
  },
];

export default Assumptions;
