const steps = [
  {
    target: '.load_btn',
    disableBeacon: true,
    content:
      'Click here to add a new resource type, scaling curve, or input/output data series to PEARL. Once added, the new measurement units, curve, or time series will be available for all users. Make sure to upload at least one day before use as it will take up to one day to be available.',
  },
  {
    target: '.form__field',
    content:
      'Click here to open the Facility form and fill in the high-level information of the facility that you want to create. Note: You need to enter at least facility name to be able to save the facility.',

    locale: {
      next: `Start`,
      skip: `Skip`,
    },
  },
  {
    target: '.side_bar',
    content:
      'After saving the facility information, please grab the required component (facility building block) from the side bar on the right and drop them onto the editing area on the left.',
  },
  {
    target: '.react-flow__node-Input_Resources',
    content:
      'Click on this element to specify facility input (input or available resource) parameters. To delete this element, click on the element to open up the form and hit the delete button.',
  },
  {
    target: '.react-flow__node-process ',
    content: 'You can drag and drop multiple elements, as required.',
  },
  {
    target: '.react-flow__edge-path',
    content:
      'Create the connection lines by joining two elements. Hover over element edge to get the pointer and connect to the desired element.',
  },
  { target: '.fac_Output', content: 'Don`t forget to add output to your facility.' },
  {
    target: '.reset_btn',
    content:
      'Click on this button to clear the entire facility. This will remove all components and connections. To make this change permanent, hit save.',
  },
  {
    target: '.save',
    content:
      'Click here to save the facility you created or edited. Note: The minimum requirement to save a facility is specifying the Facility Name.',
  },
  {
    target: '.saveAS_data',
    content:
      'Click here to save the current facility as a new facility. This is useful when you need to make small changes to an existing facility, but dont want to lose the original.',
  },
  {
    target: '.Delete',
    content: 'Click here to delete the facility. This will help you to keep your list of facilities clean.',
  },
  {
    target: '.run',
    content:
      'Once you are satisfied with the facility model you have created, click here to run operation simulations of this facility. Note: Before running you will be asked to specify simulation parameters such as simulation start date, simulation length, etc. according to your requirements.',
  },
  {
    target: '.pearl',
    content: 'Click here to return to the PEARL application Home Page',
  },
  {
    target: '.search',
    content: 'Click here to disable the zoom in and out functionality on scroll.',
  },
  {
    target: '.tour',
    content: 'Click this button to start the tour again.',
  },
];

export default steps;
