import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import { Grid, Box } from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
import ReactTooltip from 'react-tooltip';
import CustomTooltip from '../Utils/CustomTooltip';
import NumberFormat from 'react-number-format';
import CreateTable from 'components/Utils/CreateTable';

const validate = (values) => {
  const errors = {};
  if (values.low_boundary_physical_max) {
    if (values.high_boundary_physical_max < values.low_boundary_physical_max) {
      errors.high_boundary_physical_max = 'should be more than low boundary physical max';
    }
  }
  if (values.low_boundary_OPEX) {
    if (values.high_boundary_OPEX < values.low_boundary_OPEX) {
      errors.high_boundary_OPEX = 'should be more than low boundary';
    }
  }

  if (values.low_boundary_CAPEX) {
    if (values.high_boundary_CAPEX < values.low_boundary_CAPEX) {
      errors.high_boundary_CAPEX = 'should be more than low boundary Capex';
    }
  }

  return errors;
};

const CurrencyFormat = ({
  other,
  prefix,
  id,
  placeholder,
  required,
  name,
  unit,
  label,
  min,
  ref,
  input,
  meta: { touched, error, warning },
}) => (
  <>
    <label>
      {label}
      {prefix}
    </label>
    <NumberFormat
      {...other}
      {...input}
      required={required}
      id={id}
      name={name}
      value={input.value}
      placeholder={placeholder}
      allowNegative={false}
      thousandSeparator
      getInputRef={ref}
      decimalScale={2}
      allowEmptyFormatting={true}
      isNumericString
    />
    <p>{unit}</p>
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </>
);

let ConnectionForm = (props) => {
  const { handleSubmit, load, pristine, reset, submitting } = props;
  const dispatch = useDispatch();
  const ElementData = useSelector((state) => state.comp.components);
  let data = ElementData.filter((element) => element.id === props.id);

  const [viewData, setViewData] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const [isAvai, setIsAvai] = useState(false);
  const [isDem, setIsDem] = useState(false);

  const [isInputPriority, setInputPriority] = useState(false);
  const [isOutputPriority, setOuputPriority] = useState(false);

  const DemandConnection = ElementData.filter((element) => element.target === props.id);
  const AvailableConnection = ElementData.filter((element) => element.source === props.id);
  const isAvailableNode = AvailableConnection.filter((element) => element.target.includes('node'));
  const isDemandeNode = DemandConnection.filter((element) => element.source.includes('node'));
  const letters = ['No group'];
  for (let i = 65; i <= 90; i++) {
    letters.push('Group ' + String.fromCharCode(i));
  }
  useEffect(() => {
    if (AvailableConnection.length === 1 || ['Input_Resources'].includes(data[0].type)) {
      setIsExternal(true);
      setIsAvai(true);
      setIsDem(false);
    } else if (DemandConnection.length === 1 || ['Output_Resources'].includes(data[0].type)) {
      setIsExternal(true);
      setIsAvai(false);
      setIsDem(true);
    } else if (
      AvailableConnection.length === 1 ||
      DemandConnection.length === 1 ||
      ['External_Data'].includes(data[0].type)
    ) {
      setIsExternal(true);
      setIsAvai(false);
      setIsDem(false);
    } else {
      setIsExternal(false);
      setIsAvai(false);
      setIsDem(false);
    }
  }, [ElementData, data, props.id, DemandConnection, AvailableConnection]);

  let newObj = Object.assign(
    {
      resource_id: '',
      input_custom_priority: 0,
      output_custom_priority: 0,
      value_nl_id: 1,
      CAPEX_nl_id: 1,
      OPEX_nl_id: 1,
    },
    data[0].data
  );

  useEffect(() => {
    if (['node'].includes(data[0].target?.split('_')[0]) || isAvailableNode.length === 1) {
      setInputPriority(true);
    }
    if (['node'].includes(data[0].source?.split('_')[0]) || isDemandeNode.length === 1) {
      setOuputPriority(true);
    }
  }, [data, newObj, isAvailableNode, isDemandeNode]);

  if (data[0].type === 'smoothstep') {
    newObj = Object.assign(
      {
        resource_id: '',
        low_boundary_CAPEX: 0,
        high_boundary_CAPEX: 0,
        high_boundary_OPEX: 0,
        low_boundary_OPEX: 0,
      },
      newObj
    );
  }

  console.log('node', newObj, isInputPriority, isOutputPriority);
  const [color, setColor] = useState(newObj.color);

  useEffect(() => {
    load(newObj);
  }, [newObj, load]);

  const [resource, setResource] = useState(newObj?.resource_id);

  const removeElementFromStore = (id) => {
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
    dispatch(elementActions.modifyData());
  };

  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };

  const setResourceHandler = (event) => {
    setResource(event.target.value);
  };

  var unit = (resource) => {
    let res = props.Resources.filter((data) => data.id.toString() === resource.toString()).map((d) => {
      return d.Units;
    });
    return res;
  };

  const [isRequired, SetIsRequired] = useState(true);

  const saveForLater = () => {
    SetIsRequired(false);
  };

  const setValidation = () => {
    dispatch(elementActions.SetFormValidatation(props.id));
    dispatch(elementActions.changeConnection(props.id));
  };

  function ViewDataHandler(event) {
    event.preventDefault();
    setViewData(!viewData);
  }

  let availableTSD = props.External.filter((e) => e.Direction.toLowerCase() === 'input');
  let demandTSD = props.External.filter((e) => e.Direction.toLowerCase() === 'output');

  return (
    <form
      onSubmit={handleSubmit((values) => {
        console.log('submit', values);
        if (!values.available_data_series_id || !values.demand_data_series_id) {
          console.log('no data series', values.isValidated);
        }

        values = { ...values, color: color };
        if (isRequired === true) {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }

        updateData(values);
        props.togglePopup();
      })}
    >
      <div>
        <Grid container spacing={1} columns={16}>
          <Grid item xs={5}>
            <label>Name:</label>
            <Field
              component="input"
              name="label"
              type="text"
              id="FormName"
              className="opt_name"
              required={isRequired}
              placeholder="Enter the Name"
            />
          </Grid>
          {isExternal ? (
            <>
              <Grid item xs={4}>
                <div>
                  <CustomTooltip
                    id="group"
                    text="Plants & external connections in the same group will scale up & down together /
              Select the same group letter [A-Z] to place different components into the same group or leave /
              'no group' if you don't want link components."
                  />
                  <label>Group: </label>
                  <Field
                    name="group"
                    component="select"
                    value="No group"
                    required={isRequired}
                    id="group"
                    className="opt"
                  >
                    {letters.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="color-picker">
                  <label>Element color:</label>
                  <ColorPicker
                    hideTextfield
                    name="color"
                    value={color}
                    onChange={(e) => setColor(e.css.backgroundColor)}
                  />
                </div>
              </Grid>
            </>
          ) : (
            ''
          )}
        </Grid>
      </div>

      <div className="resource" id="res">
        <label>Resources:</label>
        <Field
          component="select"
          name="resource_id"
          parse={(value) => (value ? parseFloat(value) : '')}
          value="Linear"
          id="resource_id"
          required={isRequired}
          onChange={setResourceHandler}
        >
          <option />
          {props.Resources.map((data) => (
            <option key={data.id} value={data.id}>
              {data.Name} {`[HHV: ${parseFloat(data.HHV).toFixed(2)}]`}
            </option>
          ))}
        </Field>
        <label>{`${unit(resource)}/hr`} </label>
      </div>

      <div className="form_container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid columns={1}>
            <label>Enter limits for sampling and select scaling curves:</label>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <label>Low Boundary:</label>
            </Grid>
            <Grid item xs={3}>
              <label>High Boundary:</label>
            </Grid>
            <Grid item xs={4}>
              <label>Scaling Curve:</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip
                id="max"
                text="Maximum size (or capacity) of the connection. This much resource can flow from one end to the other every hour."
              />
              <label>Size:</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_physical_max"
                className="opt_input"
                required={isRequired}
                component={CurrencyFormat}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                id="low_boundary_physical_max"
                min="0.0"
                unit={`${unit(resource)}/hr`}
              />
              {/* <p> {unit(resource)} </p> */}
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_physical_max"
                className="opt_input"
                component={CurrencyFormat}
                min="0.0"
                id="high_boundary_physical_max"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                unit={`${unit(resource)}/hr`}
              />
              {/* <p> {unit(resource)} </p> */}
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="value_nl_id"
                parse={(value) => (value ? parseFloat(value) : '')}
                className="dropdown"
                value="Linear"
                required={isRequired}
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip id="Capex" text="CAPEX needed to install the connection with the specified size." />

              <label>CAPEX:</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_CAPEX"
                className="opt_input"
                component={CurrencyFormat}
                id="low_boundary_CAPEX"
                min="0.0"
                unit="USD"
                prefix="$"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
              />
              {/* <p>USD</p> */}
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_CAPEX"
                className="opt_input"
                component={CurrencyFormat}
                id="high_boundary_CAPEX"
                min="0.000"
                unit="USD"
                prefix="$"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
              />
              {/* <p>USD</p> */}
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="CAPEX_nl_id"
                parse={(value) => (value ? parseFloat(value) : '')}
                required={isRequired}
                className="dropdown"
                value="Linear"
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip id="OPEX" text="OPEX per year required to run this connection at this size." />

              <label>OPEX:</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                min="0"
                id="low_boundary_OPEX"
                unit="USD/yr"
                prefix="$"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
              />
              {/* <p>USD/yr</p> */}
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                min="0"
                id="high_boundary_OPEX"
                unit="USD/yr"
                prefix="$"
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="OPEX_nl_id"
                parse={(value) => (value ? parseFloat(value) : '')}
                className="dropdown"
                value="Linear"
                required={isRequired}
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Box>
      </div>

      {isExternal ? (
        <>
          <label>Time Series:</label>
          {!viewData ? (
            <button type="viewbtn" onClick={ViewDataHandler}>
              View Details
            </button>
          ) : (
            <button type="Delbutton" onClick={ViewDataHandler}>
              Hide Details
            </button>
          )}
          <div className="form_container2">
            {!isDem && (
              <>
                {' '}
                <label>Available:</label>
                <Field
                  component="select"
                  disabled={isDem}
                  name="available_data_series_id"
                  required={isRequired}
                  parse={(value) => Number(value)}
                  id="Available"
                >
                  <option />
                  {availableTSD.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.Name}
                    </option>
                  ))}
                </Field>{' '}
              </>
            )}

            {!isAvai && (
              <>
                {' '}
                <label>Demand:</label>
                <Field
                  component="select"
                  name="demand_data_series_id"
                  id="Demand"
                  required={isRequired}
                  disabled={isAvai}
                  parse={(value) => Number(value)}
                >
                  <option />
                  {demandTSD.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.Name}
                    </option>
                  ))}
                </Field>{' '}
              </>
            )}
          </div>

          {viewData ? (
            <div className="form_container2">
              {' '}
              <CreateTable data={props.External} />
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ' '
      )}
      {isInputPriority || isOutputPriority ? (
        <>
          <label>Custom Priority:</label>

          <div className="form_container2">
            <Box sx={{ flexGrow: 1 }}>
              {/* <Grid columns={1}></Grid> */}
              <Grid container spacing={2} columns={16}>
                <Grid item xs={3}>
                  {' '}
                  {isOutputPriority ? (
                    <label>
                      <CustomTooltip
                        id="out_priority"
                        text="Select the priority by which this connection comes out of the connected node."
                      />
                      Output:
                      <Field
                        name="output_custom_priority"
                        className="opt"
                        component="input"
                        type="number"
                        step="1"
                        min="0"
                        max="9"
                        id="output_custom_priority"
                        required={isRequired}
                        placeholder="0"
                        parse={(value) => Number(value)}
                      />{' '}
                    </label>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={2}></Grid>

                <Grid item xs={4}>
                  {isInputPriority ? (
                    <label>
                      <CustomTooltip
                        id="in_priority"
                        text="Select the priority by which this connection goes into the connected node."
                      />
                      Input:
                      <Field
                        name="input_custom_priority"
                        className="opt"
                        component="input"
                        type="number"
                        step="1"
                        min="0"
                        max="9"
                        id="input_custom_priority"
                        required={isRequired}
                        placeholder="0"
                        parse={(value) => Number(value)}
                      />
                    </label>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      ) : (
        ''
      )}

      <div className="btn-ctn">
        <button type="submit" id="sub_form" onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater} data-tip data-for="save_later_form">
          Save Draft
        </button>
        <ReactTooltip id="save_later_form" className="toolBox" place="bottom" effect="solid">
          complete form later
        </ReactTooltip>
        <button type="btn" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
        <button type="Delbutton" id="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ConnectionForm = reduxForm({
  form: 'ExternalData',
  validate,
  // a unique identifier for this form
})(ConnectionForm);

// // You have to connect() to any reducers that you wish to connect to yourself
ConnectionForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(ConnectionForm);

export default ConnectionForm;
