import '../Utils/fileupload.css';
import '../ElementForms/Form.css';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import UploadFile from 'components/Data/UploadFile';
import UploadFileSuccess from 'components/Data/UploadFileSuccess';
import { useDispatch } from 'react-redux';
import { toggleActions } from 'components/Store/TogglePopup';
import CustomTooltip from './CustomTooltip';
import { NotificationActions } from 'components/Store/SendNotifications';
import Papa from 'papaparse';
import { check, emptycheckHandler, nonNegativeHandler, lonlatHandler, findObject } from '../Utils/utils';
import { v4 as uuidv4 } from 'uuid';
import ProgressBar from './ProgressBar';

const content = [
  {
    filetype: 'resource',
    header: ['Name', 'Units', 'HHV'],
    headerstring: 'Name,Units,HHV,UUID\n',
    message: 'The value of the HHV field cannot be negative.',
  },
  {
    filetype: 'timeseries',
    header: [
      'Name',
      'Description',
      'Type',
      'Direction',
      'Latitude',
      'Longitude',
      'Provider',
      'Country',
      'Datetime',
      'Value',
    ],
    headerstring: 'Name,Description,Type,Direction,Latitude,Longitude,Provider,Country,Datetime,Value,UUID\n',
    message: 'There are values other than numbers between 0 and 1 in the Value field.',
  },
  {
    filetype: 'scalingcurve',
    header: [
      'Function',
      'y_0pc',
      'y_5pc',
      'y_10pc',
      'y_15pc',
      'y_20pc',
      'y_25pc',
      'y_30pc',
      'y_35pc',
      'y_40pc',
      'y_45pc',
      'y_50pc',
      'y_55pc',
      'y_60pc',
      'y_65pc',
      'y_70pc',
      'y_75pc',
      'y_80pc',
      'y_85pc',
      'y_90pc',
      'y_95pc',
      'y_100pc',
    ],
    headerstring:
      'Function,y_0pc,y_5pc,y_10pc,y_15pc,y_20pc,y_25pc,y_30pc,y_35pc,y_40pc,y_45pc,y_50pc,y_55pc,y_60pc,y_65pc,y_70pc,y_75pc,y_80pc,y_85pc,y_90pc,y_95pc,y_100pc,UUID\n',

    message: 'There are values other than numbers between 0 and 1 in the fields.',
  },
];
const papaParsePromise = (file) =>
  new Promise((resolve) => Papa.parse(file, { download: true, complete: resolve, skipEmptyLines: true }));

const papaParsePromiseHeader = (file) =>
  new Promise((resolve) => Papa.parse(file, { header: true, download: true, complete: resolve, skipEmptyLines: true }));

const validateFile = async (file, filetype) => {
  console.log(typeof file);
  let finalarray = { typecheck: true, emptycheck: true, nonNegative: true, lonlatcheck: true };
  let valid = false;

  if (file) {
    const data = await papaParsePromise(file);
    let arrayofArrays = data.data;
    let checkcommas = findObject(arrayofArrays);

    if (arrayofArrays) {
      finalarray.emptycheck = emptycheckHandler(arrayofArrays);
      if (filetype === 'resource') {
        finalarray.typecheck = check(arrayofArrays, content[0].header);
        finalarray.nonNegative = nonNegativeHandler(arrayofArrays, 'resource');
      } else if (filetype === 'scalingcurve') {
        finalarray.nonNegative = nonNegativeHandler(arrayofArrays, 'scalingcurve');
        finalarray.typecheck = check(arrayofArrays, content[2].header);
      } else if (filetype === 'timeseries') {
        finalarray.typecheck = check(arrayofArrays, content[1].header);
        finalarray.nonNegative = nonNegativeHandler(arrayofArrays, 'timeseries');
        finalarray.lonlatcheck = lonlatHandler(arrayofArrays);
      }
      if (
        finalarray.nonNegative === true &&
        finalarray.lonlatcheck === true &&
        finalarray.emptycheck === true &&
        finalarray.typecheck === true &&
        checkcommas === true
      ) {
        valid = 'There is a comma in one of the fields. Please remove it and re-submit.';
      } else if (
        finalarray.nonNegative === true &&
        finalarray.lonlatcheck === true &&
        finalarray.emptycheck === false &&
        finalarray.typecheck === true &&
        checkcommas === false
      ) {
        valid = 'There are empty values in the fields.';
      } else if (
        finalarray.nonNegative === false &&
        finalarray.lonlatcheck === true &&
        finalarray.emptycheck === true &&
        finalarray.typecheck === true &&
        checkcommas === false
      ) {
        valid = content
          .filter((e) => e.filetype === filetype)
          .map((d) => {
            return d.message;
          });
      } else if (
        finalarray.nonNegative === true &&
        finalarray.lonlatcheck === false &&
        finalarray.emptycheck === true &&
        finalarray.typecheck === true &&
        checkcommas === false
      ) {
        valid = 'There are invalid values in the latitude or longitude field.';
      } else if (
        finalarray.nonNegative === true &&
        finalarray.lonlatcheck === true &&
        finalarray.emptycheck === true &&
        finalarray.typecheck === true &&
        checkcommas === false
      ) {
        valid = true;
      } else {
        valid = false;
      }
    }
  }
  return valid;
};

const fileTypes = ['csv'];

const refData = [
  {
    name: 'Resources',
    value: 'resource',
  },
  {
    name: 'Scaling Curve',
    value: 'scalingcurve',
  },
  {
    name: 'Time Series Data',
    value: 'timeseries',
  },
];

const UploadFileForm = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const getInitialState = () => {
    const value = 'resource';
    return value;
  };

  const [value, setValue] = useState(getInitialState);
  const [isProgressStart, setIsProgressStart] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const handleSelectChange = (e) => {
    setValue(e.target.value);
  };
  const handleChange = (e) => {
    setFile(e);
    setFileName(e.name);
  };
  // console.log('e', value, `${value}.csv`, typeof file);
  const csvName = refData.filter((data) => data.value === value).map((name) => name.name);

  function pSetTimeout(timeout) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), timeout);
    });
  }

  const Upload = async () => {
    let not = {};
    let val = await validateFile(file, value);
    if (file) {
      let uuid = uuidv4();
      if (val === true) {
        dispatch(toggleActions.setIsWarnNotif(false));
        setIsProgressStart(true);
        const csvdata = await papaParsePromiseHeader(file);
        const array = await papaParsePromise(file);
        const headers = content
          .filter((e) => e.filetype === value)
          .map((d) => {
            return d.header;
          });

        var hed = [...headers[0], 'UUID'];
        console.log(' filter', csvdata.data[0][hed[0]]);
        csvdata.data.forEach((e) => (e.UUID = uuid));
        console.log('re', csvdata, array);
        let headerRow = content
          .filter((e) => e.filetype === value)
          .map((d) => {
            return d.headerstring;
          });
        let tmpCSV = '';
        let counter = 10000;
        tmpCSV = headerRow[0];
        console.log(' uuidv4()', csvdata.data.length, uuid, csvdata, tmpCSV);
        var count = 0;
        for (var i = 0; i < csvdata.data.length; i++) {
          let del = ',';

          for (var k = 0; k < hed.length; k++) {
            if (k === hed.length - 1) {
              del = '\n';
            }
            tmpCSV = tmpCSV.concat(csvdata.data[i][hed[k]] + del);
          }

          if (i !== 0 && i % counter === 0) {
            not = await UploadFile({ fileType: value, file: tmpCSV });
            count = count + 1;
            setPercentage(Math.round((i / csvdata.data.length) * 100));
            await pSetTimeout(1000);
            tmpCSV = '';
            tmpCSV = headerRow[0];
          }
        }

        if (tmpCSV !== '') {
          not = await UploadFile({ fileType: value, file: tmpCSV });
          await pSetTimeout(1000);
          console.log('here empty', not);
          count = count + 1;
        }

        let filecompletecheck = {
          uid: uuid,
          data_series_name: csvdata.data[0][hed[0]],
          row_count: csvdata.data.length,
          num_files: count,
          fileType: value,
        };

        const result = await UploadFileSuccess(filecompletecheck);
        pSetTimeout(1000);

        if (result.success) {
          dispatch(toggleActions.setIsWarnNotif(false));
          setPercentage(100);
          await pSetTimeout(2000);
          dispatch(toggleActions.uploadFile());
          dispatch(
            NotificationActions.showNotification({
              status: 'success',
              title: 'success',
              message: `${csvName[0]} successfully submitted for upload.
              It may take a few minutes for the upload process to complete, and
              the new data to appear on the drop-down lists.`,
            })
          );
        } else if (result.error) {
          dispatch(toggleActions.setIsWarnNotif(true));
          dispatch(
            NotificationActions.showWarningNotification({
              status: 'warning',
              title: 'fail',
              message: 'Failed to upload the csv',
            })
          );
        }
      } else {
        dispatch(toggleActions.setIsWarnNotif(true));
        dispatch(
          NotificationActions.showWarningNotification({
            status: 'warning',
            title: 'fail',
            message: "The uploaded file's headers do not match the selected file type. Tip: Use templates",
          })
        );
      }
    } else {
      dispatch(toggleActions.setIsWarnNotif(true));
      dispatch(
        NotificationActions.showWarningNotification({
          status: 'warning',
          title: 'warning',
          message: 'Please upload the file First',
        })
      );
    }
  };

  const wrongFileType = () => {
    dispatch(toggleActions.setIsWarnNotif(true));
    dispatch(
      NotificationActions.showWarningNotification({
        status: 'warning',
        title: 'fail',
        message: 'Wrong file type. Can only accept csv format.',
      })
    );
  };
  return (
    <div>
      <ul>
        <li className="title">Please select the reference data type for which you want to upload data:</li>
        <select autoFocus value={value} onChange={handleSelectChange}>
          {refData.map((data) => (
            <option key={data.value} value={data.value}>
              {data.name}
            </option>
          ))}
        </select>

        <span>
          <CustomTooltip
            id="down"
            text="Please use the template to add your data by overwriting/removing the first row of data. Do not use commas in any field."
            info="fa-lg info-imp"
          />
        </span>

        <div>
          <li className="title">Please use the reference template. Download it from here:</li>
          <div className="btnctn">
            <button className="download">
              <a
                href={`${value}.csv`}
                style={{ color: 'black', cursor: 's-resize', textDecoration: 'none' }}
                target="_blank"
                download={`${value}.csv`}
                className="uplLine"
                rel="noreferrer"
              >
                {` Download
                ${refData
                  .filter((data) => data.value === value)
                  .map((filteredData) => {
                    if (filteredData.name === 'Time Series Data') {
                      return filteredData.name;
                    }
                    return filteredData.name + ' data';
                  })}
                template here`}{' '}
                <i className="fas fa-download" />
              </a>
            </button>
          </div>
        </div>
        <div className="content">
          <li className="title">Once completed, please drop the CSV file here: </li>
          <div className="btnctn">
            <div className="drop-message">
              <FileUploader
                onDrop={handleChange}
                hoverTitle="Drop here"
                handleChange={handleChange}
                classes="drop_area drop_zone"
                name="file"
                onTypeError={wrongFileType}
                types={fileTypes}
              >
                <i className="fas fa-file-csv fa-2x"></i>
                {fileName === ''
                  ? 'Click here to upload, or drop file here.'
                  : `File Uploaded successfully: ${fileName}`}
              </FileUploader>
            </div>
          </div>
        </div>
        <li className="title">Finally, press the "upload" button below to add new reference data.</li>
        <div className="btnctn">
          <button type="success" className="success" onClick={Upload}>
            Upload <i className="fas fa-upload" />
          </button>
        </div>
      </ul>
      {isProgressStart ? (
        <div>
          <ProgressBar isLoading={false} percent={percentage} size={'large'} showInfo={true} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default UploadFileForm;
