import '../Utils/fileupload.css';
import '../ElementForms/Form.css';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
// import { elementActions } from 'components/Store/ElementSlice';
import { useDispatch } from 'react-redux';
import { toggleActions } from 'components/Store/TogglePopup';
// import CustomTooltip from './CustomTooltip';
import { NotificationActions } from 'components/Store/SendNotifications';
import { elementActions } from 'components/Store/ElementSlice';
import { useHistory } from 'react-router-dom';
import SendData from 'components/SaveData/SendData';

function fromBinary(encoded) {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const UploadFac = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFilename] = useState(null);

  const handleChange = async (e) => {
    console.log('file', e.name.split('.').pop());
    dispatch(toggleActions.setIsNotif(false));

    if (e.name.split('.').pop() === 'prl') {
      setFilename(e.name);

      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile(JSON.parse(fromBinary(binaryStr)));
      };
      reader.readAsBinaryString(e);
    } else {
      wrongFileType(e, 'Wrong file type. Can only accept prl format.');
    }
  };

  const changeids = async (data) => {
    console.log('data', data);
    const makeId = () => {
      return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
    };

    let facid = makeId();
    const objCopy = JSON.parse(JSON.stringify({ ...data, facilityId: facid }));
    console.log('objCopy', objCopy);

    objCopy.components.filter((el) => el.type === 'node').map((element) => (element.data.facility_id = facid));

    objCopy.components.map((el) => {
      const sourceCon = objCopy.components.filter(
        (element) => element.id.split('_')[0] === 'connection' && element.source === el.id
      );

      const targCon = objCopy.components.filter(
        (element) => element.id.split('_')[0] === 'connection' && element.target === el.id
      );

      el.data.id = makeId();

      el.data.UIid = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
      el.id = el.id.replace(/_\d+/g, '') + '_' + el.data.id;

      if (sourceCon) {
        if (sourceCon.length > 0) {
          for (var ii = 0; ii < sourceCon.length; ii++) {
            sourceCon[ii].source = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
          }
        } else {
          sourceCon.source = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
        }
      }

      if (targCon) {
        if (targCon.length > 0) {
          for (var i = 0; i < targCon.length; i++) {
            targCon[i].target = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
          }
        } else {
          targCon.target = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
        }
      }
      return el;
    });
    console.log('data after', objCopy);

    return objCopy;
  };

  const upload = async () => {
    if (file !== null) {
      const newdata = await changeids(file);
      dispatch(toggleActions.uploadFac());
      dispatch(elementActions.RestoreElement(newdata));
      dispatch(toggleActions.setFacilityLoaded(true));
      if (newdata.isPrioritySet === false) {
        dispatch(toggleActions.setIsNotif(true));
        dispatch(
          NotificationActions.showNotification({
            status: 'error',
            title: 'fail',
            message: 'Selected facility is from old version. Please specify the connection priorities!',
          })
        );
      }
      if (!newdata.isPrioritySet) {
        newdata.isPrioritySet = false;
        await SendData({ isPrioritySet: false, ...newdata });
      } else {
        await SendData(newdata);
      }
      history.push('/App');
    } else {
      wrongFileType(null, 'Please upload the file first');
    }
  };

  const wrongFileType = (e, message) => {
    console.log(e);
    dispatch(toggleActions.setIsNotif(true));

    dispatch(
      NotificationActions.showNotification({
        status: 'error',
        title: 'fail',
        message: message,
      })
    );
  };

  return (
    <div>
      <ul>
        <div className="content">
          <div className="btnctn">
            <div className="drop-message">
              <FileUploader
                onDrop={handleChange}
                hoverTitle="Drop here"
                handleChange={handleChange}
                classes="drop_area drop_zone"
                name="file"
              >
                <i className="fas fa-cloud"></i>
                {fileName === null
                  ? 'Click here to upload, or drop .prl file here'
                  : `File Uploaded successfully: ${fileName}`}
              </FileUploader>
            </div>
          </div>
        </div>
        <div className="btnctn">
          <button type="success" className="success" onClick={upload}>
            Upload <i className="fas fa-upload" />
          </button>
        </div>
      </ul>
    </div>
  );
};

export default UploadFac;
