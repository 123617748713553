import CheckRes from './CheckRes';
import SendData from './SendData';

function checkGroups(components) {
  const groups = {};
  console.log('grups???', groups);
  components.forEach((component) => {
    if (
      component.data &&
      component.type !== 'smoothstep' &&
      component.data.group &&
      component.data.group !== 'No group'
    ) {
      if (groups[component.data.group]) {
        groups[component.data.group] += 1;
      } else {
        groups[component.data.group] = 1;
      }
    }
  });
  return groups;
}

export const ReformatData = async (ElementData) => {
  let list = { changeColor: [], setIsSucess: false, success: '', error: '', warnings: '' };

  const nonValidatedFacility = ElementData.isValidated;
  const nonValidatedForm = ElementData.components.filter((el) => el.data.isValidated === false);
  const Input_Resources = ElementData.components.filter((el) => el.type === 'Input_Resources');
  const Output_Resources = ElementData.components.filter((el) => el.type === 'Output_Resources');
  const External = ElementData.components.filter((el) => el.type === 'External_Data');
  const noConnections = ElementData.components.filter((el) => el.type.toString() === 'smoothstep');
  const numComponent = ElementData.components.filter((el) => el.type.toString() !== 'smoothstep');
  const groupstype = checkGroups(ElementData.components);
  console.log('ElementData group', groupstype);

  let singleElementGroups = [];

  for (let group in groupstype) {
    if (groupstype[group] === 1) {
      singleElementGroups.push(group);
    }
  }

  let noderes = CheckRes('node', ElementData, noConnections);
  let storageres = CheckRes('storage', ElementData, noConnections);
  ElementData = { ...ElementData, isPrioritySet: true };

  if (ElementData.components.length === 0) {
    list.warnings = 'Make a facility to run C++ script';
  } else if (singleElementGroups.length > 0) {
    list.warnings = 'The following groups have only one element: ' + singleElementGroups.join(', ');
  } else if (storageres[0] === false) {
    list.warnings =
      'Please make sure you use the same resource type for all connections attached to ' +
      storageres[1].map((el) => el) +
      ',';
  } else if (noderes[0] === false) {
    list.warnings =
      'Please make sure you use the same resource type for all connections attached to ' +
      noderes[1].map((el) => el) +
      ',';
  } else if (nonValidatedFacility === false) {
    list.warnings = 'Please provide facility details on the Facility form before continuing.';
  } else if (nonValidatedForm.length !== 0) {
    list.warnings = 'please complete the details of ' + nonValidatedForm.map((el) => el.id) + ', ';
    nonValidatedForm.map((el) => {
      return list.changeColor.push(el.id);
    });
  } else if (External.length > 0 && External.length < 2) {
    list.warnings = 'Facility needs to have atleast 2 external connections before running the C++ script ';
  } else if (External.length === 0 && Input_Resources.length < 1) {
    list.warnings = 'Facility needs to have atleast 1 Input_Resources before you can run simulation';
  } else if (External.length === 0 && Output_Resources.length < 1) {
    list.warnings = 'Facility needs to have atleast 1 Output_Resources before you can run simulation';
  } else if (Object.keys(numComponent).length >= 2 && Object.keys(noConnections).length === 0) {
    list.warnings = 'Please add connection between components, cannot run facility unconnected';
  } else if (Object.keys(numComponent).length - Object.keys(noConnections).length > 1) {
    list.warnings = 'Please add connection between elements';
  } else {
    list.setIsSucess = true;
    ElementData = { ...ElementData, isFacilityValidated: true };
  }

  const message = await SendData(ElementData);

  list.success = message.success;
  list.error = message.error;

  console.log('list', list);
  return list;
};
