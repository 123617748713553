import React, { memo, CSSProperties, useState, useEffect } from 'react';

import { Handle, Position } from 'react-flow-renderer';

const sourceHandleStyle: CSSProperties = { background: '#555' };

const MultiOutput = (data: any) => {
  const [nodelabel, setNodeLabel] = useState('');

  useEffect(() => {
    setNodeLabel(data.data.label);
  }, [data.data]);

  const rightupID = `${data.id.split('_')[0]}_rightup`;
  const rightdownID = `${data.id.split('_')[0]}_rightdowm`;

  const leftID = `${data.id.split('_')[0]}_left`;

  return (
    <>
      <Handle id={rightupID} type="source" position={Position.Right} style={{ top: '30%', borderRadius: 0 }} />
      <Handle id={rightdownID} type="source" position={Position.Right} style={{ top: '70%', borderRadius: 0 }} />
      &nbsp;
      <span id="node_name">{nodelabel} </span>
      <Handle type="target" position={Position.Left} id={leftID} style={sourceHandleStyle} />
    </>
  );
};

export default memo(MultiOutput);
