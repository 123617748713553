import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigActions } from 'components/Store/ConfigSlice';
import { NotificationActions } from 'components/Store/SendNotifications';
import RunCplus from 'components/RunFacility/RunCplus';
import { toggleActions } from 'components/Store/TogglePopup';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './Form.css';
import { normalizePercentageConfig, formatPercentage, formatSingleQuote, normalizeSingleQuote } from '../Utils/utils';
import Switch from '@material-ui/core/Switch';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import CustomTooltip from 'components/Utils/CustomTooltip';

const validate = (values) => {
  const errors = {};
  if (values.start_time) {
    if (
      moment(new Date(values.start_time._d)) > moment(new Date('01/01/2100 12:00:00 AM')) ||
      moment(new Date(values.start_time._d)) < moment(new Date('01/01/1950 12:00:00 AM'))
    ) {
      console.log('val', moment(new Date('01/01/2100 12:00:00 AM')));
      errors.start_time = 'Date out of range, must be between year 1950 to 2100.';
    }
  }

  return errors;
};

const valid = (current) => {
  return current.isAfter('1950-01-01') && current.isBefore('2100-01-01');
};

const CustomDatetimePicker = ({ input, className, inputProps, meta: { touched, error, warning } }) => (
  <>
    <Datetime {...input} {...inputProps} className={className} isValidDate={valid} />
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}{' '}
  </>
);

const CurrencyFormat = ({ other, className, id, placeholder, required, name, label, ref, input }) => (
  <>
    <label>{label}$</label>
    <NumberFormat
      {...other}
      {...input}
      required={required}
      id={id}
      name={name}
      className={className}
      value={input.value}
      placeholder={placeholder}
      allowNegative={false}
      thousandSeparator
      getInputRef={ref}
      decimalScale={2}
      allowEmptyFormatting={true}
      isNumericString
    />{' '}
  </>
);

let ConfigForm = (props) => {
  const { handleSubmit, load, pristine, change, reset, submitting } = props;
  const dispatch = useDispatch();
  const [units, setUnits] = useState();

  const [showAd, setShowAd] = useState(false);

  let elementData = useSelector((state) => state.comp.components).filter((e) => e.type === 'process');
  let ExternalData = useSelector((state) => state.comp.components).filter(
    (e) => ['External_Data', 'Output_Resources'].includes(e.type) && e.data.demand_data_series_id
  );
  const concatArr = useCallback((arr1, sep) => {
    let result = arr1.reduce(function (prev, curr, index) {
      return prev + sep + `${curr[0] + sep}[${curr[1]}]`;
    }, '');
    return result;
  }, []);

  useEffect(() => {
    if (ExternalData) {
      let resName = props.Resources.filter(({ id }) => ExternalData.map((e) => e.data.resource_id).includes(id));
      let uniName = resName.map((item, i) => [item.Name, item.Units]);

      setUnits(concatArr(uniName, ' '));
      console.log('f', ExternalData);
    }
  }, [ExternalData, concatArr, props.Resources]);

  const handleToggleChange = () => {
    setShowAd(!showAd);
  };
  let configData = useSelector((state) => state.config);

  let arr = [];
  const facId = useSelector((state) => state.comp.facilityId);

  for (var i = 0; i < elementData.length; i++) {
    arr.push(1);
  }

  configData = { ...configData, facilityId: facId };
  configData = {
    ...configData,
    baseline_agent_behaviour: arr,
    end_time: moment(moment(configData.end_time, 'YYYY-MM-DD HH:00:00')._i, 'YYYY-MM-DD HH:00:00').format(
      'DD/MM/YYYY hh:mm A'
    ),
    start_time: moment(new Date(configData.start_time)),
  };

  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const [years, setYears] = useState(1);
  const [start, setStart] = useState(moment(new Date(configData.start_time)));

  const runScript = useCallback(
    async (values) => {
      let RunNotif = await RunCplus(values);
      dispatch(toggleActions.setIsNotif(true));
      console.log('Run', RunNotif);
      if (RunNotif.RunSuccess) {
        dispatch(
          NotificationActions.showNotification({
            status: 'success',
            title: 'Successfully Run',
            message:
              'The facility config has been sent for processing. You should receive an email once it has completed.',
          })
        );
      }
      if (RunNotif.RunError) {
        dispatch(
          NotificationActions.showNotification({
            status: 'error',
            title: 'UI failed to send the facility configurations for processing',
            message: `${RunNotif.RunError}`,
          })
        );
      } else if (RunNotif.ScriptError) {
        dispatch(
          NotificationActions.showNotification({
            status: 'error',
            title: ' ScriptError fail',
            message: RunNotif.ScriptError,
          })
        );
      }
    },
    [dispatch]
  ); //facId

  useEffect(() => {
    load(configData);
  }, [configData, load]);

  const updateData = (values) => {
    dispatch(ConfigActions.updateConfigData(values));
  };

  const [isRequired, SetIsRequired] = useState(true);

  const saveForLater = () => {
    SetIsRequired(false);
  };
  var timezones = moment.tz.names();
  const filteredOptions = timezones.filter((zone) => zone !== timeZone);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        values = {
          ...values,
          start_time: moment.tz(moment(values.start_time), timeZone).format('YYYY-MM-DD HH:00:00 Z'),
          end_time: moment
            .tz(moment(moment(values.end_time, 'DD/MM/YYYY hh:mm:ss A')._i, 'DD/MM/YYYY hh:mm:ss A'), timeZone)
            .format('YYYY-MM-DD HH:00:00 Z'),
        };

        updateData(values);
        if (isRequired) {
          runScript(values);
        }
        props.togglePopup();
      })}
    >
      <label>Configuration:</label>
      <div className="form_container">
        <div className="column70">
          <div>
            <label>Timezone: </label>
            <Field
              component="select"
              className="formField"
              onChange={(e) => setTimeZone(e.target.value)}
              required
              name="TimeZone"
              id="TimeZone"
            >
              <option key={0} value={timeZone}>
                {timeZone}{' '}
              </option>
              {filteredOptions.map((data) => (
                <option key={data} value={data}>
                  {data}
                </option>
              ))}
            </Field>
          </div>
          <div>
            <label>Start time: </label>
            <Field
              name="start_time"
              className="starttime"
              component={CustomDatetimePicker}
              inputProps={{
                timeFormat: true,
                closeOnSelect: true,
                dateFormat: 'DD/MM/YYYY',
                //https://codesandbox.io/s/mq0o5p8xqj?file=/src/index.js:1188-1225
              }}
              onChange={(e) => {
                console.log('e', e);
                setStart(moment(new Date(e._d)).format('DD/MM/YYYY hh:mm:ss A'));
                const changeDate1 = moment(new Date(e._d)).add(years, 'years');
                change('end_time', moment(changeDate1._d).format('DD/MM/YYYY hh:mm A'));
              }}
              id="start_time"
              min="1950-01-01 12:00AM"
              max="2100-01-01 12:00AM"
              required={isRequired}
            />
          </div>
          <div>
            <label>Length of simulation: </label>
            <Field
              name="num_years"
              component="input"
              type="number"
              min="1"
              id="num_years"
              onChange={(e) => {
                setYears(Number(e.target.value));
                const changeDate = moment(start, 'DD/MM/YYYY hh:mm:ss A').add(Number(e.target.value), 'years');
                change('end_time', moment(changeDate._d).format('DD/MM/YYYY hh:mm A'));
              }}
              className="con"
              required={isRequired}
            />{' '}
            yrs
          </div>

          <div>
            <label>End time: </label>

            <Field name="end_time" component="input" type="datetime" disabled id="end_time" required={isRequired} />
          </div>
        </div>
        <div className="column">
          <div>
            <label>
              Discount rate:
              <Field
                name="discount_rate"
                component="input"
                type="number"
                step="0.5"
                format={formatPercentage}
                normalize={normalizePercentageConfig}
                required={isRequired}
                id="discount_rate"
                className="con"
                min="0"
                max="100"
              />
              %
            </label>
          </div>
          <div>
            <label>
              Inflation rate:
              <Field
                name="inflation_rate"
                component="input"
                type="number"
                step="0.5"
                format={formatPercentage}
                normalize={normalizePercentageConfig}
                required={isRequired}
                id="inflation_rate"
                className="con"
                min="0"
                max="100"
              />
              %
            </label>
          </div>
          <div>
            <label>
              Construction time:
              <Field
                name="years_to_build"
                component="input"
                type="number"
                step="1"
                required={isRequired}
                id="years_to_build"
                className="con"
                min="0"
              />
              yrs
            </label>
          </div>
        </div>
      </div>
      <div className="form_container2">
        <div className="column">
          <div>
            <label>
              <CustomTooltip
                id="des"
                text="Description of the model run.
What are you trying to find? What are the objectives? How is this model run different from the previous one?"
              />
              Calculation description:
            </label>{' '}
          </div>
          <Field
            component="textarea"
            name="description"
            type="text"
            id="description"
            format={formatSingleQuote}
            normalize={normalizeSingleQuote}
            required={isRequired}
            placeholder="Decribe the calculation"
          />
        </div>
        <div className="column">
          <div>
            <label>
              Produce outputs:
              <Field name="export_to_database" component="input" type="checkbox" className="opt" />
            </label>
          </div>
        </div>
      </div>
      <div className="form_container2">
        <div className="column">
          <div>
            <label>Minimum daily production: </label>
            <Field
              name="min_prod"
              component="input"
              type="number"
              step="1"
              id="min_prod"
              className="config"
              min="1"
              required={isRequired}
            />
            <span id="units">{units}</span>
          </div>
          <div>
            <label>Max CAPEX: </label>
            <Field
              name="max_CAPEX"
              component={CurrencyFormat}
              value="0"
              id="max_CAPEX"
              parse={(value) => (value ? parseFloat(value.replace(/,/g, '')).toString() : '')}
              required={isRequired}
              min="1"
              className="config"
            />
            USD
          </div>
        </div>
      </div>
      <label> Advanced Configuration </label>
      <Switch id="toggle" onChange={handleToggleChange} />
      {showAd && (
        <>
          <div className="form_container2">
            <div className="column">
              <div>
                <label>
                  # simulations for boundary updates:
                  <Field
                    name="n_top_trajectories"
                    component="input"
                    type="number"
                    step="1"
                    id="n_top_trajectories"
                    className="con"
                    min="1"
                    required={isRequired}
                  />{' '}
                </label>
              </div>
              <div>
                <label>
                  # calculation loops:
                  <Field
                    name="n_unsupervised_loops"
                    component="input"
                    type="number"
                    step="1"
                    id="n_unsupervised_loops"
                    className="con"
                    min="1"
                    required={isRequired}
                  />
                </label>
              </div>
            </div>
            <div className="column">
              <div>
                <label>
                  # simulations per loop:
                  <Field
                    name="n_trajectories_per_loop"
                    component="input"
                    type="number"
                    step="1"
                    id="n_trajectories_per_loop"
                    className="con"
                    min="1"
                    required={isRequired}
                  />
                </label>
              </div>
              <div>
                <label>
                  <CustomTooltip
                    id="using_rl"
                    place="left"
                    text={
                      <>
                        By selecting the RL option, please note that the run time will be significantly longer, taking
                        approximately 6-10 days to complete. It is important to keep in mind that this option will also
                        result in a higher cloud infrastructure cost of ~$500 USD per run, due to the extended run time.
                        Please consider this carefully before selecting the RL option.
                      </>
                    }
                  />
                  Reinforcement Learning:
                  <Field name="using_rl" component="input" type="checkbox" className="opt" />
                </label>
              </div>
              <div>
                <label>
                  <CustomTooltip id="traj" place="left" text="Get Trajectory details for 1 year" />
                  Trajectory Details:
                  <Field name="get_traj" component="input" type="checkbox" className="opt" value="True" />
                </label>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="btn-ctn">
        <button className="submit" id="sub_form">
          Save and Run C++
        </button>
        <button className="submit" id="save_later_form" onClick={saveForLater}>
          Save Draft
        </button>
        <button type="btn" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ConfigForm = reduxForm({
  form: 'config',
  validate,
  // a unique identifier for this form
})(ConfigForm);

// // You have to connect() to any reducers that you wish to connect to yourself
ConfigForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(ConfigForm);

export default ConfigForm;
