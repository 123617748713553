import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toggleActions } from 'components/Store/TogglePopup';

import { elementActions } from '../Store/ElementSlice';
import './Buttons.css';
import './Facility.css';
import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

let FacilityNameForm = () => {
  const dispatch = useDispatch();
  const reduxFacName = useSelector((state) => state.comp.facilityName);
  const isFormOpen = useSelector((state) => state.toggleForm.formIsVisible);

  const [facName, setFacName] = useState('');

  const toggleFac = () => {
    dispatch(toggleActions.toggleFacForm(!isFormOpen));
  };
  useEffect(() => {
    setFacName(reduxFacName);
  }, [reduxFacName]);

  const handleSubmit1 = (event) => {
    if (event._reactName === 'onChange') {
      dispatch(elementActions.addFacilityName(event.target.value));
    } else {
      event.preventDefault();
      dispatch(elementActions.addFacilityName(facName));
    }
  };

  return (
    <div className="form__group" data-tip data-for="facNameEdit" id="Fac_Name">
      <form onSubmit={handleSubmit1}>
        <input
          component="input"
          label="facilityName"
          className="form__field"
          name="name"
          onClick={toggleFac}
          id="Input_Name_fac"
          type="text"
          placeholder="Facility Name"
          value={facName || ''}
          onChange={(e) => {
            handleSubmit1(e);
            setFacName(e.target.value);
          }}
        />

        <label htmlFor="Fac_Name" onClick={toggleFac} id="facName" className="form__label">
          FacilityName
        </label>

        <ReactTooltip id="facNameEdit" className="toolBox" place="bottom" effect="solid">
          Edit facility parameters
        </ReactTooltip>
      </form>
    </div>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FacilityNameForm = reduxForm({
  form: 'FacilityName',
})(FacilityNameForm);

export default FacilityNameForm;
