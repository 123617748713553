import './Navbar.css';
import { authProvider } from '../../authProvider';
import { Link } from 'react-router-dom';

import Buttons from './Buttons';
import ReactTooltip from 'react-tooltip';
import Notification from './Notification';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const accountInfo = authProvider.getAccountInfo();
  const account = accountInfo.account;
  const isUploadFile = useSelector((state) => state.toggleForm.isUploadFile);

  const reset = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div>
      {!isUploadFile && <Notification />}

      <div className="Navbar">
        <Link id="title" className="label pearl" to={'/'} onClick={reset} data-tip data-for="Route_page">
          PEARL
        </Link>

        <ReactTooltip id="Route_page" className="toolBox" place="bottom" effect="solid">
          Return to home page
        </ReactTooltip>
        <nav>
          <ul>
            <li>
              <Buttons />
            </li>
          </ul>
          <ul>
            <li>Welcome, {account.name.split(' ')[0]}</li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
