import React from 'react';
import ReactTooltip from 'react-tooltip';
import './CustomTooltip.css';

const CustomTooltip = ({ id, text, place = 'right', info = 'fa-lg info' }) => {
  return (
    <>
      <i className={`fas fa-info-circle ${info}`} data-tip data-for={id}>
        {' '}
      </i>
      <ReactTooltip multiline={true} className="tooltip" style={{ width: 300 }} id={id} place={place} effect="solid">
        {text}
      </ReactTooltip>
      &nbsp;
    </>
  );
};

export default CustomTooltip;
