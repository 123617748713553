import { createSlice } from '@reduxjs/toolkit';
import Electrolyser from 'components/Data/Electrolyser';
import nh3Template from '../Data/nh3Template';

const ElementSlice = createSlice({
  name: 'component',
  initialState: {
    facilityId: '',
    facilityLocation: '',
    facilityProject: '',
    facilityName: '',
    description: '',
    fixed_CAPEX: '',
    fixed_OPEX: '',
    isValidated: false,
    isFacilityValidated: false,
    deleteFacility: false,
    isPrioritySet: false,
    components: [],
  },
  reducers: {
    // Delete facility parameter, this parameter is used to hide the facility from the drop down
    deleteFacilityHandler(state, action) {
      state.deleteFacility = action.payload;
    },
    // sets the paramater for complete facility as in if it has passed the validation steps or not
    setIsFacilityValidated(state, action) {
      state.isFacilityValidated = action.payload;
    },

    setIsPrioritySet(state, action) {
      state.isPrioritySet = action.payload;
    },
    // removes all the components

    ResetElements(state) {
      state.components = [];
    },

    // used to recreate the loaded facility
    RestoreElement(state, action) {
      const newFac = action.payload;

      newFac.components
        .filter((el) => el.type.toString() === 'smoothstep')
        .map((element) => (element.data.input_custom_priority ? '' : (element.data.input_custom_priority = 0)));
      newFac.components
        .filter((el) => el.type.toString() === 'smoothstep')
        .map((element) => (element.data.output_custom_priority ? '' : (element.data.output_custom_priority = 0)));

      newFac.components
        .filter((el) => !['smoothstep', 'node'].includes(el.type.toString()))
        .map((element) => (element.data.group ? '' : (element.data.group = 'No group')));

      newFac.components
        .filter((el) => el.type.toString() === 'smoothstep')
        .map((element) =>
          /COST|PPA|Tx_Pi|NODE_JOINT/.test(element.label.toString())
            ? (element.labelBgStyle.fill = '#cddc39')
            : (element.labelBgStyle.fill = '#d4d4d4')
        );
      if (!newFac.isPrioritySet) {
        newFac.isPrioritySet = false;
      }

      const newComponents = newFac.components.map((component, index) => {
        if (component.data.elNumber) {
          return component;
        }
        return Object.assign({}, component, { data: Object.assign({}, component.data, { elNumber: index + 1 }) });
      });

      newFac.components = newComponents;

      console.log('new', newFac);
      state.components = newFac.components;
      state.facilityName = newFac.facilityName;
      state.facilityLocation = newFac.facilityLocation;
      state.facilityProject = newFac.facilityProject;
      state.description = newFac.description;
      state.fixed_CAPEX = newFac.fixed_CAPEX;
      state.fixed_OPEX = newFac.fixed_OPEX;
      state.facilityId = newFac.facilityId;
      state.isValidated = newFac.isValidated;
      state.description = newFac.description;
      state.isPrioritySet = newFac.isPrioritySet;
      state.isFacilityValidated = newFac.isFacilityValidated;
    },

    // node element requires facility id as a parameter and it is added here
    addFacilityId(state, action) {
      state.facilityId = action.payload;
      state.components.filter((el) => el.type === 'node').map((element) => (element.data.facility_id = action.payload));
    },

    // creates new ids on "save as" for each elements and connections along with that updated source and target
    //in connection to ensure the connectivity between to elements
    updateElementIds(state) {
      const makeId = () => {
        return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
      };

      state.components.map((el) => {
        const sourceCon = state.components.filter(
          (element) => element.id.split('_')[0] === 'connection' && element.source === el.id
        );

        const targCon = state.components.filter(
          (element) => element.id.split('_')[0] === 'connection' && element.target === el.id
        );

        el.data.id = makeId();

        el.data.UIid = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
        el.id = el.id.replace(/_\d+/g, '') + '_' + el.data.id;

        if (sourceCon) {
          if (sourceCon.length > 0) {
            for (var ii = 0; ii < sourceCon.length; ii++) {
              sourceCon[ii].source = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
            }
          } else {
            sourceCon.source = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
          }
        }

        if (targCon) {
          if (targCon.length > 0) {
            for (var i = 0; i < targCon.length; i++) {
              targCon[i].target = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
            }
          } else {
            targCon.target = el.id.replace(/_\d+/g, '') + '_' + el.data.id;
          }
        }
        return el;
      });
    },

    // facility data gets updated here
    updateFacilityData(state, action) {
      const newFac = action.payload;

      if (newFac.length === 0) {
        state.facilityLocation = '';
        state.facilityProject = '';
        state.facilityName = '';
        state.description = '';
        state.fixed_CAPEX = '';
        state.fixed_OPEX = '';
        state.description = '';
        state.isValidated = false;
      } else if (newFac === 'saveAs') {
        state.facilityName = '';
      } else {
        state.facilityLocation = newFac.facilityLocation;
        state.facilityProject = newFac.facilityProject;
        state.facilityName = newFac.facilityName;
        state.description = newFac.description;
        state.fixed_CAPEX = newFac.fixed_CAPEX;
        state.fixed_OPEX = newFac.fixed_OPEX;
      }
    },

    // facility name gets updated here
    addFacilityName(state, action) {
      const newFacilityName = action.payload;
      state.facilityName = newFacilityName;
    },

    // bool for valid facility form
    updateIsFacilityValidated(state, action) {
      state.isValidated = action.payload;
    },

    // it adds element to the component lists, some elements have their own templates
    addElement(state, action) {
      const newElement = action.payload;
      console.log(newElement);
      const existingElement = state.components.find((element) => element.id === newElement.id);
      let isValid = '';
      if (newElement.type === 'node') {
        isValid = true;
      } else {
        isValid = newElement.data.isValidated;
      }

      if (!existingElement) {
        if (newElement.data.label === 'NH₃ plant') {
          state.components.push(nh3Template(newElement));
        } else if (newElement.data.label === 'Electrolyser') {
          state.components.push(Electrolyser(newElement));
        } else if (['Input_Resources', 'Output_Resources'].includes(newElement.type)) {
          state.components.push({
            id: newElement.id,
            type: newElement.type,
            position: newElement.position,
            className: newElement.className,
            sourcePosition: newElement.sourcePosition,
            targetPosition: newElement.targetPosition,
            data: {
              id: newElement.data.id,
              UIid: newElement.id,
              type: newElement.data.type,
              name: newElement.data.label,
              color: newElement.data.color,
              input_custom_priority: 0,
              output_custom_priority: 0,
              label: newElement.data.label,
              isValidated: isValid,
              group: 'No group',
              elNumber: newElement.data.elNumber,
            },
            style: {
              backgroundColor: newElement.data.color,
            },
          });
        } else if (newElement.type === 'node') {
          state.components.push({
            id: newElement.id,
            type: newElement.type,
            position: newElement.position,
            className: newElement.className,
            sourcePosition: newElement.sourcePosition,
            targetPosition: newElement.targetPosition,
            data: {
              id: newElement.data.id,
              UIid: newElement.id,
              type: newElement.data.type,
              name: newElement.data.label,
              color: newElement.data.color,
              label: newElement.data.label,
              isValidated: isValid,
              elNumber: newElement.data.elNumber,
            },
            style: {
              backgroundColor: newElement.data.color,
            },
          });
        } else {
          state.components.push({
            id: newElement.id,
            type: newElement.type,
            position: newElement.position,
            className: newElement.className,
            sourcePosition: newElement.sourcePosition,
            targetPosition: newElement.targetPosition,
            data: {
              id: newElement.data.id,
              UIid: newElement.id,
              type: newElement.data.type,
              name: newElement.data.label,
              color: newElement.data.color,
              label: newElement.data.label,
              isValidated: isValid,
              group: 'No group',
              elNumber: newElement.data.elNumber,
            },
            style: {
              backgroundColor: newElement.data.color,
            },
          });
        }
      } else {
        existingElement.position = newElement.position;
      }
    },

    // connection is added here in the component list
    // this payload gets list of all the elements present in the components
    // therefore a filter is required to get the new connection
    addConnection(state, action) {
      const Connection = action.payload;

      const existingConnection = Connection.filter((edge) => edge.data.type === 'connection');
      const newConnection = existingConnection[existingConnection.length - 1];
      // console.log('Conn', newConnection.label, existingConnection);

      const existing = state.components.filter(
        (edge) =>
          edge.data.type === 'connection' &&
          edge.source === newConnection.source &&
          edge.target === newConnection.target
      );

      var Validated = false;
      var cursorType = '';
      var fill = '';

      if (/COST|PPA|Tx_Pi|NODE_JOINT/.test(newConnection.label.toString())) {
        fill = '#cddc39';
      } else {
        fill = '#d4d4d4';
      }

      if (
        ['External', 'Input'].includes(newConnection.source.split('_')[0]) ||
        ['External', 'Output'].includes(newConnection.target.split('_')[0])
      ) {
        cursorType = 'not-allowed';
        Validated = true;
      } else {
        cursorType = 'pointer';
      }

      if (existingConnection && existing.length === 0) {
        state.components.push({
          ...newConnection,
          target: newConnection.target,
          source: newConnection.source,
          sourceHandle: newConnection.sourceHandle,
          targetHandle: newConnection.targetHandle,
          label: newConnection.label,
          data: {
            ...newConnection.data,
            label: newConnection.label,
            isValidated: Validated,
          },
          style: { cursor: cursorType },
          labelBgStyle: { cursor: cursorType, fill: fill },
        });
      }
    },

    // updates connection data here, also it has different codes for input output and connection type
    // also to ensure the compatibility with old facility that contained external type as well
    updateConnection(state, action) {
      const Connection = action.payload;
      console.log('conn update', Connection.labelBgStyle.fill);

      const noEx =
        ['Input', 'External'].includes(Connection.source.split('_')[0]) ||
        ['Output', 'External'].includes(Connection.source.split('_')[0]);

      let cursorType = '';

      if (noEx) {
        cursorType = 'not-allowed';
      } else {
        cursorType = 'pointer';
      }

      let conEl_Sr = state.components.filter((e) => e.id === Connection.source);
      let conEl_tr = state.components.filter((e) => e.id === Connection.target);

      let SrLabel = conEl_Sr.map((e) => e.data.label);
      let TrLabel = conEl_tr.map((e) => e.data.label);

      const existingConn = state.components.find((element) => element.id === Connection.id);

      if (existingConn) {
        state.components = state.components
          .filter((element) => element.id !== Connection.id)
          .concat({
            ...Connection,
            label: `${SrLabel}_to_${TrLabel}`,
            style: { cursor: cursorType },
            labelBgStyle: { cursor: cursorType, fill: Connection.labelBgStyle.fill },
          });
      }
    },

    // deletes element from the list
    removeElement(state, action) {
      const el_id = action.payload.id;

      const delConn = state.components.find((conn) => conn.source === el_id);
      const delOutputConn = state.components.find((conn) => conn.target === el_id);
      if (delConn) {
        state.components = state.components.filter((element) => element.source !== el_id && element.id !== el_id);
      }
      if (delOutputConn) {
        state.components = state.components.filter((element) => element.target !== el_id && element.id !== el_id);
      }

      state.components = state.components.filter((element) => element.id !== el_id);
    },

    // updated element data, forms data comes here
    updateElementData(state, action) {
      const data = action.payload;

      const existingElement = state.components.find((element) => element.data.id === data.values.id);

      existingElement.data = data.values;
      existingElement.data.name = data.values.label;
      existingElement.data.label = data.values.label;
      existingElement.style = {
        ...existingElement.style,
        backgroundColor: data.values.color,
      };

      const ifConn = state.components.find(
        (element) => element.type === 'smoothstep' && element.data.id === data.values.id
      );
      if (ifConn) {
        ifConn.label = data.values.label;
        if (/COST|PPA|Tx_Pi|NODE_JOINT/.test(ifConn.label.toString())) {
          console.log('here');
          ifConn.labelBgStyle.fill = '#cddc39';
        } else {
          ifConn.labelBgStyle.fill = '#d4d4d4';
        }
      }
      // to change the source label in the connected connection line
      const SourceconnectionsExists = state.components.find(
        (element) => element.id.split('_')[0] === 'connection' && element.source === data.values.UIid
      );
      if (SourceconnectionsExists && SourceconnectionsExists.label.includes('_to_')) {
        console.log('SourceconnectionsExists.label ', SourceconnectionsExists.label);
        SourceconnectionsExists.label = SourceconnectionsExists.label
          .toString()
          .replace(/^(.*?)_to/, data.values.name + '_to');
        if (/COST|PPA|Tx_Pi|NODE_JOINT/.test(data.values.name || SourceconnectionsExists.label)) {
          SourceconnectionsExists.labelBgStyle.fill = '#cddc39';
        } else {
          SourceconnectionsExists.labelBgStyle.fill = '#d4d4d4';
        }
      }
      // to change the target label in the connected connection line

      const targetconnectionsExists = state.components.find(
        (element) => element.id.split('_')[0] === 'connection' && element.target === data.values.UIid
      );
      if (targetconnectionsExists && targetconnectionsExists.label.includes('_to_')) {
        console.log('targetconnectionsExists.label ', targetconnectionsExists.label);

        targetconnectionsExists.label = targetconnectionsExists.label
          .toString()
          .replace(/to.*/gm, 'to_' + data.values.name);
        if (/COST|PPA|Tx_Pi|NODE_JOINT/.test(data.values.name || targetconnectionsExists.label)) {
          targetconnectionsExists.labelBgStyle.fill = '#cddc39';
        } else {
          targetconnectionsExists.labelBgStyle.fill = '#d4d4d4';
        }
      }

      // to change the external data series label in the connected connection line
      if (['Output', 'Input', 'External'].includes(data.values.UIid.split('_')[0])) {
        const connectionsExists = state.components.find(
          (element) =>
            element.id.split('_')[0] === 'connection' &&
            (element.source === data.values.UIid || element.target === data.values.UIid)
        );

        if (connectionsExists) {
          console.log('here');
          connectionsExists.label = data.values.label;
          if (/COST|PPA|Tx_Pi|NODE_JOINT/.test(connectionsExists.label)) {
            connectionsExists.labelBgStyle.fill = '#cddc39';
          } else {
            connectionsExists.labelBgStyle.fill = '#d4d4d4';
          }
        }
      }
    },

    // set validation if form is complete or incomplete
    SetFormValidatation(state, action) {
      const id = action.payload;

      const existingElement = state.components.find((element) => element.id === id);

      existingElement.data.isValidated = true;
    },

    changeConnection(state, action) {
      const id = action.payload;

      const existingElement = state.components.find((element) => element.id === id);

      if (['Input', 'External', 'Output'].includes(id.toString().split('_')[0])) {
        console.log('connected external');
        const conn = state.components.filter(
          (element) => (element.id.split('_')[0] === 'connection' && element.source === id) || element.target === id
        );

        conn.map((e) => (e.animated = false));
      }

      if (existingElement.id.split('_')[0] === 'connection') {
        existingElement.animated = false;
      }
    },

    // changes colour of element depending on if it is complete or incomplete on facility save
    changeElColour(state, action) {
      const id = action.payload;

      const existingElement = state.components.find((element) => element.id === id);
      existingElement.style = {
        ...existingElement.style,
        backgroundColor: 'grey',
      };

      if (existingElement.id.split('_')[0] === 'connection') {
        existingElement.animated = true;
      }
    },

    // modifies the data of connection line connected to input/output resource element
    modifyData(state) {
      state.components.forEach(function (conn) {
        state.components.forEach(function (ExtSr) {
          if (['Input_Resources', 'External_Data'].includes(ExtSr.type) && conn.source === ExtSr.id) {
            const conn_data = conn.data;
            conn.data = { ...ExtSr.data, id: conn_data.id, UIid: conn_data.UIid, isValidated: conn_data.isValidated };
          }
        });
      });

      state.components.forEach(function (conn) {
        state.components.forEach(function (ExtTar) {
          if (['Output_Resources', 'External_Data'].includes(ExtTar.type) && conn.target === ExtTar.id) {
            const conn_data = conn.data;
            conn.data = { ...ExtTar.data, id: conn_data.id, UIid: conn_data.UIid, isValidated: conn_data.isValidated };
          }
        });
      });
    },
  },
});

export const elementActions = ElementSlice.actions;

export default ElementSlice;
