import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const btn = (light, dark) => css`
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  &:visited {
    color: white;
  }
  background-image: linear-gradient(${light}, ${dark});
  border: 2px solid ${dark};
  &:hover {
    background-image: linear-gradient(${light}, ${dark});
    &[disabled] {
      background-image: linear-gradient(${light}, ${dark});
    }
  }
  &:visited {
    color: black;
  }
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const upbt = css`
  ${btn('yellow', 'red')} color: white;
`;

const success = css`
  ${btn('green', 'darkgreen')} color: white;
`;

const btnDefault = css`
  ${btn('#ffffff', '#d5d5d5')} color: #555;
`;
const btnDel = css`
  ${btn('red', 'maroon')} color: white;
`;

const viewbtn = css`
  ${btn('#4d90ca', '#2a6293')} color: black;
`;

const btnPrimary = btn('#4f93ce', '#285f8f');

export default styled.div`
  font-family: sans-serif;

  h1 {
    text-align: center;
    color: white;
    
  }

  & > div {
    text-align: center;
    color:white;
    line-height: 3em;
  }

  a {
    display: block;
    text-align: center;
    color: white;
  }

}
  form {
   
    & > div {
      line-height: 3em;
      display: flex;
      flex-flow: row nowrap;
      line-height: 1em;
      align-items: baseline;
    
      margin: 5px;
      & > label {
        color: white;
        width: 110px;
        font-size: 1em;
        line-height: 32px;
      }
      & > p{
        colour: white;
        font-size: 2em;
      }
      
      & > input,
      & > select,
      & > option,
      & > optgroup,
   {
        flex: 1;
        padding: 1px 3px;
      
        font-size: 1em;
        margin-left: 15px;
    
        border-radius: 3px;
        width: -webkit-fill-available;
        
      }
      & > input[type='checkbox'] {
        margin-top: 7px;
        padding:2px;
      }
      & > div {
        line-height: 3em;
        margin-left: 16px;
        & > label {
          display: block;
          & > input {
            margin-right: 3px;
          }
        }
      }
    }
    & > .buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-top: 15px;
    }
    button {
      margin: 0 10px;
      &[type='submit'] {

        ${btnPrimary};
      }
      &[type='btn'] {
        ${btnDefault};
      }
      &[type='Delbutton'] {
        ${btnDel};
      }
      &[type='upbtn']{
        ${upbt};
      }
      &[type='viewbtn']{
        ${viewbtn}
        }
      &[type='success']{
        ${success};
      }
      header{
        color:yellow;
      }
    }
    
    pre {
      border: 1px solid #ccc;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
      padding: 20px;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  conatiner: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  root: {
    background: 'transparent;',
    border: '2px solid #f94d0b',
    fontSize: 18,
    margin: 3,
    marginTop: 10,
    width: '8rem',
    borderRadius: '8px',
    boxShadow: '0 3px 5px 2px rgb(156, 156, 156, .3)',
    color: 'white',
    height: 48,
    padding: '5px',
    '&:hover , &:active': {
      '&:not([disabled])': {
        backgroundColor: '#fff',
        background: 'linear-gradient(45deg, #999da0, #46535a)',
        color: '#000000 !important',
        cursor: 'pointer',
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      fontSize: '18px',
      opacity: '0.65',
    },
  },
  del: {
    border: '2px solid #f94d0b',
    fontSize: 18,
    background: 'transparent;',
    color: 'white',
    margin: 3,
    marginTop: 10,
    width: '10rem !important',
    borderRadius: '8px',
    boxShadow: '0 3px 5px 2px rgb(156, 156, 156, .3)',
    height: 48,
    padding: '5px',
    '&:hover , &:active': {
      '&:not([disabled])': {
        backgroundColor: '#fff',
        background: 'linear-gradient(45deg, red, maroon)',
        color: 'white !important',
        cursor: 'pointer',
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      fontSize: '18px',
      opacity: '0.65',
    },
  },
  upload: {
    border: '2px solid #f94d0b',
    fontSize: 16,
    background: 'transparent;',
    color: 'white',
    margin: 3,
    marginTop: 10,
    width: '10rem !important',
    borderRadius: '8px',
    boxShadow: '0 3px 5px 2px rgb(156, 156, 156, .3)',
    height: 48,
    padding: '5px 3px 5px 2px',
    '&:hover , &:active': {
      '&:not([disabled])': {
        backgroundColor: '#fff',
        background: 'linear-gradient(45deg, #999da0, #46535a)',
        color: 'white !important',
        cursor: 'pointer',
      },
    },
    '&:disabled': {
      cursor: 'not-allowed',
      fontSize: '18px',
      opacity: '0.65',
    },
  },
}));
