const CheckRes = (type, ElementData, connections) => {
  const eltype = ElementData.components.filter((el) => el.type.toString() === type);
  console.log('nodes', eltype, connections);

  const numnodes = eltype.length;

  let comp = true;
  let el = [];
  for (let k = 0; k < numnodes; k++) {
    const conndata = connections.filter((e) => e.target === eltype[k].id || e.source === eltype[k].id);
    console.log('conn_data', conndata.length, eltype[k], k, comp);
    if (conndata && conndata.length > 1) {
      for (let i = 0; i < conndata.length - 1; i++) {
        if (conndata[i].data.resource_id !== conndata[i + 1].data.resource_id) {
          comp = false;
          if (!el.includes(eltype[k].id)) {
            el.push(eltype[k].id);
          }
          console.log('conn_data[i]', eltype[k].id);
        }
      }
    }
  }
  console.log('comp', comp, el);
  return [comp, el];
};

export default CheckRes;
