import React, { FC } from 'react';
import { ConnectionLineComponentProps } from 'react-flow-renderer';

const ConnectionLine: FC<ConnectionLineComponentProps> = ({ sourceX, sourceY, targetX, targetY }) => {
  return (
    <g>
      <path
        fill="none"
        stroke="black"
        strokeWidth={4.5}
        className="animated"
        id="edge"
        type="step"
        d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
      />
      <circle cx={targetX} cy={targetY} fill="white" r={5} stroke="black" strokeWidth={4.5} />
      <marker fill="black" />
    </g>
  );
};

export default ConnectionLine;
