import { authProvider } from '../../authProvider';
import { v4 as uuidv4 } from 'uuid';
const axios = require('axios');
const config = require('../../config');

const RunCplus = (configData) => {
  const accountInfo = authProvider.getAccountInfo();
  const account = accountInfo.account;
  const userName = account.userName;
  console.log('userName', accountInfo);
  configData.username = userName;
  configData.runId = uuidv4();

  console.log('con', JSON.stringify(configData, null, 2));
  let list = { RunSuccess: false, RunError: '', ScriptError: '' };
  let apiGateway = {};
  // console.log('config', config);
  console.log(window.location.hostname);
  switch (window.location.hostname) {
    case 'pearl.ai.fmgaws.cloud': {
      // prod
      apiGateway = {
        API_KEY: config.default.apiGatewayProd.API_KEY,
        API_HOST: config.default.apiGatewayProd.API_HOST,
      };
      break;
    }
    case 'pearl-test.ai.fmgaws.cloud': {
      // test
      apiGateway = {
        API_KEY: config.default.apiGatewayTest.API_KEY,
        API_HOST: config.default.apiGatewayTest.API_HOST,
      };
      break;
    }
    default: {
      // dev
      apiGateway = {
        API_KEY: config.default.apiGatewayDev.API_KEY,
        API_HOST: config.default.apiGatewayDev.API_HOST,
      };
    }
  }
  try {
    axios({
      url: apiGateway.API_HOST + '/api/run',
      method: 'post',
      data: { configData },
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-api-key': apiGateway.API_KEY,
      },
    })
      .then((res) => {
        console.log(`statusCode: ${res.status}`);
        console.log('respond', res);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error response', error.response.data);
          list.RunError = error.response.data;
        }
      });
    // list.RunSuccess = true;
  } catch (err) {
    console.error(err);
  }

  list.RunSuccess = true;
  return list;
};

export default RunCplus;
