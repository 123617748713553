const NL = [
  {
    id: 1,
    Function: 'Linear',
  },
  {
    id: 2,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.01',
  },
  {
    id: 3,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.02',
  },
  {
    id: 4,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.05',
  },
  {
    id: 5,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.1',
  },
  {
    id: 6,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.2',
  },
  {
    id: 7,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=0.5',
  },
  {
    id: 8,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=1.5',
  },
  {
    id: 9,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=2',
  },
  {
    id: 10,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=5',
  },
  {
    id: 11,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=10',
  },
  {
    id: 12,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=20',
  },
  {
    id: 13,
    Function: 'EXP ASC: 1/(B-1)*(B^x-1) where B=50',
  },
  {
    id: 14,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.01',
  },
  {
    id: 15,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.02',
  },
  {
    id: 16,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.05',
  },
  {
    id: 17,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.1',
  },
  {
    id: 18,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.2',
  },
  {
    id: 19,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=0.5',
  },
  {
    id: 20,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=1.5',
  },
  {
    id: 21,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=2',
  },
  {
    id: 22,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=5',
  },
  {
    id: 23,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=10',
  },
  {
    id: 24,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=20',
  },
  {
    id: 25,
    Function: 'EXP DESC: 1-(1/(B-1)*(B^x-1)) where B=50',
  },
  {
    id: 26,
    Function: 'Linear INV',
  },
];

export default NL;
