import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from 'components/Store/ElementSlice';
import { toggleActions } from 'components/Store/TogglePopup';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { normalizeSingleQuote, formatSingleQuote } from '../Utils/utils';
import CustomTooltip from 'components/Utils/CustomTooltip';

const CurrencyFormat = ({
  other,
  input,
  label,
  name,
  placeholder,
  unit,
  className,
  required,
  min,
  ref,
  id,
  text,
  meta: { touched, error, warning },
}) => (
  <>
    <div className="column30">
      <label>{label}:</label>
    </div>
    <i className="fas fa-dollar-sign dollar"></i>

    <div className="column70">
      <NumberFormat
        {...other}
        {...input}
        required={required}
        id={id}
        name={name}
        placeholder={placeholder}
        className={className}
        allowNegative={false}
        thousandSeparator
        getInputRef={ref}
        decimalScale={2}
        allowEmptyFormatting={true}
        isNumericString
      />{' '}
    </div>
    <label>{unit}</label>
    <CustomTooltip id={`${id}_fac`} text={text} />
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </>
);

let FacilityForm = (props) => {
  const { handleSubmit, pristine, reset, submitting, load } = props;
  const dispatch = useDispatch();
  let ElementData = useSelector((state) => state.comp);
  const [isRequired, SetIsRequired] = useState(true);
  const isSaveAs = useSelector((state) => state.toggleForm.isSaveAS);

  const id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

  const updateData = (values) => {
    setTimeout(() => {
      if (isSaveAs) {
        console.log('values', values, isSaveAs);
        dispatch(toggleActions.setSendData(true));
        dispatch(toggleActions.getIsLoadRun(false));
      }
    }, 5000);
    props.togglePopup();
  };

  const saveForLater = () => {
    SetIsRequired(false);
  };

  useEffect(() => {
    load(ElementData);
  }, [ElementData, load]);

  const setValidation = () => {
    SetIsRequired(true);
    dispatch(elementActions.updateIsFacilityValidated(true));
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        console.log('fc', values);
        if (!values.facilityId) {
          dispatch(elementActions.addFacilityId(id));
        }

        if (isRequired === true) {
          dispatch(elementActions.updateIsFacilityValidated(true));
        } else {
          dispatch(elementActions.updateIsFacilityValidated(false));
        }

        values.facilityName && dispatch(toggleActions.setIsSaveDisabled(false));
        dispatch(elementActions.updateFacilityData(values));
        updateData(values);
      })}
    >
      <div>
        <div className="column30">
          <label>Location: </label>
        </div>
        <div className="column70">
          <Field
            component="input"
            label="facilityLocation"
            name="facilityLocation"
            className="opt"
            type="text"
            required={isRequired}
            id="facilityLocation"
            placeholder="Enter the project location"
          />
        </div>
      </div>
      <div>
        <div className="column30">
          <label>Project: </label>
        </div>
        <div className="column70">
          <Field
            component="input"
            label="facilityProject"
            name="facilityProject"
            className="opt"
            type="text"
            required={isRequired}
            id="facilityProject"
            placeholder="Enter the project name"
          />
        </div>
      </div>

      <div>
        <div className="column30">
          <label>Facility Name: </label>
        </div>
        <div className="column70">
          <Field
            component="input"
            label="FacilityName"
            name="facilityName"
            className="opt"
            type="text"
            required
            id="facilityName"
            placeholder="Enter the name"
          />
        </div>
      </div>

      <div>
        <Field
          name="fixed_CAPEX"
          label="Fixed CAPEX"
          className="curr"
          component={CurrencyFormat}
          prefix="$"
          unit="USD"
          id="fix_cap"
          required={isRequired}
          placeholder="0.0"
          parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
          min="0.0"
          text="CAPEX allocated to all components that are not included in the PEARL facility diagram, but are required to operate the facility. These components (site office, roads, etc.) do not change with the sizes of the Facility. Fixed CAPEX is one off for the duration of the project. This CAPEX value will be extrapolated to 25 years for reference purpose."
        />
      </div>
      <div>
        {/* <label>Fixed_Opex</label> */}
        <Field
          name="fixed_OPEX"
          label="Fixed OPEX"
          component={CurrencyFormat}
          className="curr"
          prefix="$"
          unit="USD/yr"
          required={isRequired}
          placeholder="0.0"
          parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
          id="fix_op"
          min="0.0"
          text="Yearly operating expense of to all components that are not included in the PEARL diagram, but are required to operate the facility."
        />
      </div>
      <div className="lab">
        <div className="column30">
          <label>Description: </label>
        </div>
        <div className="column70">
          <Field
            name="description"
            component="textarea"
            format={formatSingleQuote}
            normalize={normalizeSingleQuote}
            className="desc"
            placeholder="Describe the facility"
          />
        </div>
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater} data-tip data-for="save_later_form">
          Save Draft
        </button>
        <ReactTooltip id="save_later_form" className="toolBox" place="bottom" effect="solid">
          complete form later
        </ReactTooltip>
        <button type="button" className="reset" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FacilityForm = reduxForm({
  form: 'Facility',
  // a unique identifier for this form
})(FacilityForm);

// // You have to connect() to any reducers that you wish to connect to yourself
FacilityForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(FacilityForm);

export default FacilityForm;
