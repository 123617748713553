const LOAD = 'account/LOAD';

const reducer = (state, action) => {
  if (action.type === 'SUBMIT') {
    return action.payload;
  } else if (action.type === LOAD) {
    return {
      data: action.data,
    };
  } else {
    return [];
  }
};

export const load = (data) => ({ type: LOAD, data });

export default reducer;
