import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { ColorPicker } from 'material-ui-color';
import ReactTooltip from 'react-tooltip';
import { elementActions } from '../Store/ElementSlice';
import { Box, Grid } from '@material-ui/core';
import { normalizePercentage, formatPercentage } from '../Utils/utils';
import CustomTooltip from '../Utils/CustomTooltip';
import NumberFormat from 'react-number-format';

const validate = (values) => {
  const errors = {};

  if (values.low_boundary_OPEX) {
    if (values.high_boundary_OPEX < values.low_boundary_OPEX) {
      errors.high_boundary_OPEX = 'should be more than low boundary';
    }
  }

  if (values.low_boundary_CAPEX) {
    if (values.high_boundary_CAPEX < values.low_boundary_CAPEX) {
      errors.high_boundary_CAPEX = 'should be more than low boundary Capex';
    }
  }

  return errors;
};

const CurrencyFormat = ({
  other,
  prefix,
  id,
  placeholder,
  required,
  name,
  label,
  min,
  unit,
  ref,
  input,
  meta: { touched, error, warning },
}) => (
  <>
    <label>{label}$</label>
    <NumberFormat
      {...other}
      {...input}
      required={required}
      id={id}
      name={name}
      value={input.value}
      placeholder={placeholder}
      allowNegative={false}
      thousandSeparator
      getInputRef={ref}
      decimalScale={2}
      allowEmptyFormatting={true}
      isNumericString
    />{' '}
    <p>{unit}</p>
    &nbsp;
    {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
  </>
);

let ProcessForm = (props) => {
  const letters = ['No group'];
  for (let i = 65; i <= 90; i++) {
    letters.push('Group ' + String.fromCharCode(i));
  }

  const { handleSubmit, load, pristine, reset, submitting } = props;
  const dispatch = useDispatch();

  const ElementData = useSelector((state) => state.comp.components);

  const [isRequired, SetIsRequired] = useState(true);

  let data = ElementData.filter((element) => element.id === props.id);
  const newObj = Object.assign({ reward_critical_path: false, CAPEX_nl_id: 1, OPEX_nl_id: 1 }, data[0].data);
  const [color, setColor] = useState(newObj.color);

  useEffect(() => {
    load(newObj);
  }, [newObj, load]);

  const removeElementFromStore = (id) => {
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
  };

  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };

  const saveForLater = () => {
    SetIsRequired(false);
  };

  function setValidation() {
    dispatch(elementActions.SetFormValidatation(props.id));
  }

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (isRequired === true) {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }
        values = { ...values, color: color };

        updateData(values);
        props.togglePopup();
      })}
    >
      <div>
        <Grid container spacing={1} columns={16}>
          <Grid item xs={5}>
            <label>Name: </label>
            <Field
              component="input"
              label="Name"
              name="label"
              type="text"
              autoFocus
              className="opt_name"
              id="FormName"
              required={isRequired}
              placeholder="Enter the Name"
            />
          </Grid>

          <Grid item xs={4}>
            <div>
              <CustomTooltip
                id="group"
                text="Plants & external connections in the same group will scale up & down together /
              Select the same group letter [A-Z] to place different components into the same group or leave /
              'no group' if you don't want link components."
              />
              <label>Group: </label>
              <Field name="group" component="select" value="No group" required={isRequired} id="group" className="opt">
                {letters.map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </Field>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="color-picker">
              <label>Element color: </label>
              <ColorPicker
                hideTextfield
                name="color"
                className="opt_input"
                value={color}
                onChange={(e) => setColor(e.css.backgroundColor)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <label>Enter limits for sampling and select scaling curves:</label>
      <div className="form_container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid columns={1}></Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <label>Low Boundary:</label>
            </Grid>
            <Grid item xs={3}>
              <label>High Boundary:</label>
            </Grid>
            <Grid item xs={3}>
              <label>Scaling Curves:</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip
                id="Physical"
                text=" Need to specify a range (in terms of percentage of the plant’s size/capacity) in which range the model explores possible sizes for the plant."
              />

              <label>Size</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_fraction"
                className="opt_input"
                component="input"
                type="number"
                step="0.5"
                format={formatPercentage}
                normalize={normalizePercentage}
                min="0"
                max="100"
                id="low_boundary_fraction"
                required={isRequired}
                placeholder="1.0"
                unit="%"
              />
              <p>%</p>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_fraction"
                className="opt_input"
                component="input"
                type="number"
                disabled
                step="0.1"
                value="100"
                required={isRequired}
                min="1.0"
                id="high_boundary_fraction"
                placeholder="100.0"
                unit="%"
              />
              <p> % </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip
                id="Capex"
                text="CAPEX needed to install this process plant size. These are cost in USD and not cost rates!"
              />
              <label>CAPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_CAPEX"
                className="opt_input"
                component={CurrencyFormat}
                required={isRequired}
                placeholder="0.0"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                id="low_boundary_CAPEX"
                prefix="$"
              />
              <p>USD</p>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_CAPEX"
                className="opt_input"
                component={CurrencyFormat}
                value="0"
                id="high_boundary_CAPEX"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                required={isRequired}
                placeholder="0.000"
                unit="USD"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="CAPEX_nl_id"
                className="dropdown"
                value="Linear"
                parse={(value) => Number(value)}
                required={isRequired}
                id="CAPEX_nl_id"
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={2}>
              <CustomTooltip
                id="opex"
                text=" OPEX per year required to run this process plant at this size. These are cost in USD and not cost rates!"
              />

              <label>OPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                min="25.0"
                id="low_boundary_OPEX"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                unit="USD/yr"
                placeholder="0.000"
                required={isRequired}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_OPEX"
                component={CurrencyFormat}
                className="opt_input"
                id="high_boundary_OPEX"
                unit="USD/yr"
                placeholder="0.000"
                parse={(value) => (value ? parseFloat(value.replace(/,/g, '')) : '')}
                required={isRequired}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="OPEX_nl_id"
                className="dropdown"
                value="Linear"
                parse={(value) => Number(value)}
                required={isRequired}
                id="OPEX_nl_id"
              >
                <option />
                {props.scaling.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.Function}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Box>
      </div>
      <label> Enter operational parameters: </label>
      <div className="form_container2">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <CustomTooltip id="up" text="How fast the process plant can ramp up, it is specified in hours." />

                <label>Ramp up limit:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="ramp_up_limit"
                  id="ramp_up"
                  className="opt"
                  component="input"
                  required={isRequired}
                  type="number"
                  step="0.5"
                  format={formatPercentage}
                  normalize={normalizePercentage}
                  min="0"
                  max="100"
                  value="10"
                  placeholder="10.0"
                />
                %/hr
              </Grid>
              <Grid item xs={3}>
                <CustomTooltip id="down" text="How fast the process plant can ramp down, it is specified in hours." />

                <label>Ramp down limit:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="ramp_down_limit"
                  id="ramp_down_limit"
                  component="input"
                  type="number"
                  step="0.5"
                  className="opt"
                  format={formatPercentage}
                  normalize={normalizePercentage}
                  min="0"
                  max="100"
                  required={isRequired}
                  value="10"
                  placeholder="10.0"
                />
                %/hr
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <CustomTooltip
                  id="Fast"
                  text="How long it will take for the process plant to restart back from a shutdown consuming “Min throttle” of the input resources without producing any output. Fast restart is required when the shutdown duration has been less than the Shut_in_dur_threashold."
                />

                <label>Fast restart steps:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="fast_restart_steps"
                  id="fast_restart_steps"
                  component="input"
                  type="number"
                  step="0.5"
                  required={isRequired}
                  className="opt"
                  min="0"
                  parse={(value) => (value ? parseFloat(value) : '')}
                  max="100"
                  value="10"
                  placeholder="10.0"
                />
                hrs
              </Grid>
              <Grid item xs={3}>
                <CustomTooltip
                  id="slow"
                  text="How long it will take for the process plant to restart back from a shutdown consuming “Min throttle” of the input resources without producing any output. Slow restart is required when the shutdown duration has been more or equal than the Shut_in_dur_threashold."
                />

                <label>Slow restart steps:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="slow_restart_steps"
                  id="slow_restart_steps"
                  component="input"
                  type="number"
                  step="1"
                  className="opt"
                  value="0"
                  placeholder="48.0"
                  parse={(value) => (value ? parseFloat(value) : '')}
                  min="0"
                  required={isRequired}
                />
                hrs
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <CustomTooltip
                  id="Default"
                  text="Percentage of the maximum plant capacity at the start of the simulation"
                />

                <label>Default initial throttle: </label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="default_initial_status"
                  component="input"
                  type="number"
                  step="0.5"
                  value="10"
                  className="opt"
                  placeholder="10.0"
                  format={formatPercentage}
                  normalize={normalizePercentage}
                  min="0"
                  // parse={(value) => Number(value)}
                  max="100"
                  required={isRequired}
                />
                %
              </Grid>
              <Grid item xs={3}>
                <CustomTooltip
                  id="throttle"
                  text="The minimum throttle of that the process plant is allowed to work/function/operate at. Example: if plant needs to run at 40m3/hr as a minimum before it needs to shut down."
                />

                <label>Min throttle:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="min_throttle"
                  component="input"
                  type="number"
                  step="0.5"
                  value="0"
                  className="opt"
                  min="0.0"
                  max="100"
                  placeholder="10.0"
                  format={formatPercentage}
                  // parse={(value) => Number(value)}
                  normalize={normalizePercentage}
                  required={isRequired}
                />
                %
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <CustomTooltip
                  id="threshold"
                  text="The threshold that determines of a shut down is considered a short or a long shutdown."
                />

                <label>Minimum shutdown duration:</label>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="shut_in_duration_threshold"
                  component="input"
                  type="number"
                  step="1"
                  value="0"
                  className="opt"
                  placeholder="4.0"
                  min="0"
                  parse={(value) => (value ? parseFloat(value) : '')}
                  required={isRequired}
                />
                hrs
              </Grid>
              <Grid item xs={3}>
                <label>Reward in critical path:</label>
              </Grid>
              <Grid item xs={3}>
                <Field name="reward_critical_path" component="input" type="checkbox" className="opt" value="false" />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater} data-tip data-for="save_later_form">
          Save Draft
        </button>
        <ReactTooltip id="save_later_form" className="toolBox" place="bottom" effect="solid">
          complete form later
        </ReactTooltip>
        <button type="btn" disabled={pristine || submitting} onClick={reset}>
          Reset
        </button>
        <button type="Delbutton" id="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProcessForm = reduxForm({
  form: 'Process',
  validate,
  // a unique identifier for this form
})(ProcessForm);

// // You have to connect() to any reducers that you wish to connect to yourself
ProcessForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(ProcessForm);

export default ProcessForm;
