import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';

let DelaybufferForm = (props) => {
  const { handleSubmit, load, pristine, reset, submitting } = props;
  const dispatch = useDispatch();
  const [isRequired, SetIsRequired] = useState(true);
  const ElementData = useSelector((state) => state.comp.components);

  let data = ElementData.filter((element) => element.id === props.id);

  useEffect(() => {
    load(data[0]?.data);
  }, [data, load]);

  const saveForLater = () => {
    SetIsRequired(false);
  };
  const removeElementFromStore = (id) => {
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
  };

  const setValidation = () => {
    dispatch(elementActions.SetFormValidatation(props.id));
  };
  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (isRequired === true) {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }
        updateData(values);
        props.togglePopup();
      })}
    >
      <p className="Formtitle">Edit the parameters of {data[0].type} element here:</p>
      <div>
        <label>ID:</label>
        <Field component="input" disabled name="id" type="text" />
      </div>
      <div>
        <label>Type:</label>
        <Field component="input" disabled name="type" type="text" />
      </div>
      <div>
        <label>Element color:</label>
        <Field component="input" name="color" type="text" placeholder="Enter the color of component" />
      </div>
      <div>
        <label>Name:</label>
        <Field
          component="input"
          name="label"
          type="text"
          id="FormName"
          required={isRequired}
          placeholder="Enter the Name"
        />
      </div>

      <label>Operations Parameters</label>
      <div className="form_container2">
        <div className="column">
          <div>
            <label>
              Default initial status:
              <Field
                name="default_initial_status"
                component="input"
                type="number"
                step="0.1"
                required={isRequired}
                id="default_initial_status"
                value="10"
                className="opt"
                placeholder="10.0"
                min="10"
              />
              %
            </label>
          </div>
        </div>
        <div className="column">
          <div>
            <label>
              n_steps:
              <Field
                name="n_steps"
                component="input"
                type="number"
                step="0.1"
                required={isRequired}
                id="n_steps"
                value="10"
                className="opt"
                placeholder="10.0"
                min="10"
              />
              time steps
            </label>
          </div>
        </div>
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" disabled={pristine || submitting} onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater}>
          Save for Later
        </button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>
          reset
        </button>
        <button type="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DelaybufferForm = reduxForm({
  form: 'delaybuffer',
  // a unique identifier for this form
})(DelaybufferForm);

// // You have to connect() to any reducers that you wish to connect to yourself
DelaybufferForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(DelaybufferForm);

export default DelaybufferForm;
