import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../Store/account';
import './Form.css';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import NL from '../Data/NL';

import { Grid, Box } from '@material-ui/core';

let BatteryForm = (props) => {
  const { handleSubmit, load, pristine, reset, submitting } = props;
  const [low_boundary_capacity, setLow_boundary_capacity] = useState();
  const [low_boundary_CAPEX, setLow_boundary_CAPEX] = useState();
  const [low_boundary_OPEX, setLow_boundary_OPEX] = useState();

  const dispatch = useDispatch();

  const ElementData = useSelector((state) => state.comp.components);

  let data = ElementData.filter((element) => element.id === props.id);

  useEffect(() => {
    load(data[0]?.data);
  }, [data, load]);

  const removeElementFromStore = (id) => {
    dispatch(elementActions.removeElement({ id }));
  };

  const updateData = (values) => {
    dispatch(elementActions.updateElementData({ values }));
  };

  const deleteElementHandler = (event) => {
    event.preventDefault();
    removeElementFromStore(props.id);
    props.togglePopup();
  };
  const [isRequired, SetIsRequired] = useState(true);

  const saveForLater = () => {
    SetIsRequired(false);
  };

  const setValidation = () => {
    dispatch(elementActions.SetFormValidatation(props.id));
  };

  return (
    <form
      onSubmit={handleSubmit((values) => {
        if (isRequired === true) {
          values.isValidated = true;
        } else {
          values.isValidated = false;
        }

        updateData(values);
        props.togglePopup();
      })}
    >
      <p className="Formtitle">Edit the parameters of {data[0].type} Component here:</p>
      <div>
        <label>ID:</label>
        <Field component="input" disabled name="id" type="text" />
      </div>
      <div>
        <label>Type:</label>
        <Field component="input" disabled name="type" type="text" />
      </div>
      <div>
        <label>Element color:</label>
        <Field component="input" name="color" type="text" placeholder="Enter the color of component" />
      </div>
      <div>
        <label>Name:</label>
        <Field
          component="input"
          name="label"
          type="text"
          id="FormName"
          required={isRequired}
          placeholder="Enter the Name"
        />
      </div>

      <label>Operations Parameters</label>
      <div className="form_container2">
        <div className="column">
          <div>
            <label>
              Default initial status:
              <Field
                name="default_initial_status"
                component="input"
                type="number"
                step="0.1"
                required={isRequired}
                id="default_initial_status"
                value="10"
                className="opt"
                placeholder="10.0"
                min="10"
              />
              %
            </label>
          </div>
        </div>
        <div className="column">
          <div>
            <label>
              Efficiency:
              <Field
                name="efficiency"
                component="input"
                type="number"
                step="0.1"
                required={isRequired}
                id="efficiency"
                value="10"
                className="opt"
                placeholder="10.0"
                min="10"
              />
              %
            </label>
          </div>
        </div>
      </div>
      <label>Sampling size boundaries and non linearities:</label>
      <div className="form_container">
        <Box sx={{ flexGrow: 1 }}>
          <Grid columns={1}></Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <label>Low Boundary:</label>
            </Grid>
            <Grid item xs={3}>
              <label>High Boundary:</label>
            </Grid>
            <Grid item xs={4}>
              <label>Non Linearity:</label>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <label> Capacity: </label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_capacity"
                className="opt_input"
                component="input"
                type="number"
                step="0.1"
                value="1"
                min="1.0"
                onChange={(e) => {
                  setLow_boundary_capacity(e.target.value);
                }}
                id="low_boundary_capacity"
                required={isRequired}
                placeholder="1.0"
              />
              <p>[units] </p>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_capacity"
                className="opt_input"
                component="input"
                type="number"
                step="0.1"
                value="1"
                required={isRequired}
                min={low_boundary_capacity}
                id="high_boundary_capacity"
                placeholder="1.0"
              />
              <p> [units] </p>
            </Grid>
          </Grid>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={2}>
              <label>Inst. CAPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_CAPEX"
                className="opt_input"
                component="input"
                type="number"
                value="1"
                min="1.000"
                onChange={(e) => {
                  setLow_boundary_CAPEX(e.target.value);
                }}
                required={isRequired}
                id="low_boundary_CAPEX"
                step="0.01"
                placeholder="1.000"
              />
              <p>USD</p>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_CAPEX"
                component="input"
                className="opt_input"
                type="number"
                step="0.1"
                value="1"
                id="high_boundary_CAPEX"
                min={low_boundary_CAPEX}
                required={isRequired}
                placeholder="5.000"
              />
              <p>USD</p>
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="CAPEX_nl_id"
                className="dropdown"
                value="Linear"
                required={isRequired}
                id="CAPEX_nl_id"
              >
                <option />
                {NL.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={2}>
              <label>Yearly_OPEX</label>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="low_boundary_OPEX"
                component="input"
                className="opt_input"
                type="number"
                step="0.01"
                onChange={(e) => {
                  setLow_boundary_OPEX(e.target.value);
                }}
                value="25"
                min="25.0"
                id="low_boundary_OPEX"
                placeholder="25"
                required={isRequired}
              />
              <p>USD/yr</p>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="high_boundary_OPEX"
                component="input"
                type="number"
                step="0.1"
                className="opt_input"
                min={low_boundary_OPEX}
                value="55"
                id="high_boundary_OPEX"
                placeholder="55"
                required={isRequired}
              />
              <p>USD/yr</p>
            </Grid>
            <Grid item xs={4}>
              <Field
                component="select"
                name="OPEX_nl_id"
                className="dropdown"
                value="Linear"
                required={isRequired}
                id="OPEX_nl_id"
              >
                <option />
                {NL.map((data) => (
                  <option key={data.id} value={data.id}>
                    {data.name}
                  </option>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="btn-ctn">
        <button type="submit" id="sub_form" disabled={pristine || submitting} onClick={setValidation}>
          Save
        </button>
        <button type="submit" id="save_later_form" onClick={saveForLater}>
          Save for Later
        </button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>
          reset
        </button>
        <button type="Delbutton" onClick={deleteElementHandler}>
          Delete
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
BatteryForm = reduxForm({
  form: 'battery',
  // a unique identifier for this form
})(BatteryForm);

// // You have to connect() to any reducers that you wish to connect to yourself
BatteryForm = connect(
  (state) => ({
    initialValues: state.account.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(BatteryForm);

export default BatteryForm;
