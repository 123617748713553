import axios from 'axios';
import { sortBy } from 'lodash';
const config = require('../../config');

function getUnique(array, key) {
  if (typeof key !== 'function') {
    const property = key;
    key = function (item) {
      return item[property];
    };
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
      }, new Map())
      .values()
  );
}

const GetData = async (name) => {
  let apiGateway = {};

  let data = [];
  // console.log(window.location.hostname);
  switch (window.location.hostname) {
    case 'pearl.ai.fmgaws.cloud': {
      // prod
      apiGateway = {
        API_KEY: config.default.apiGatewayProd.API_KEY,
        API_HOST: config.default.apiGatewayProd.API_HOST,
      };
      break;
    }
    case 'pearl-test.ai.fmgaws.cloud': {
      // test
      apiGateway = {
        API_KEY: config.default.apiGatewayTest.API_KEY,
        API_HOST: config.default.apiGatewayTest.API_HOST,
      };
      break;
    }
    default: {
      // dev
      apiGateway = {
        API_KEY: config.default.apiGatewayDev.API_KEY,
        API_HOST: config.default.apiGatewayDev.API_HOST,
      };
    }
  }
  try {
    await axios
      .get(apiGateway.API_HOST + '/' + name + '/', {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'x-api-key': apiGateway.API_KEY, // prod
        },
      })
      .then((res) => {
        console.log(`statusCode: ${res.status}`);

        data = { ...res.data };
      })
      .catch((error) => {
        console.error('error', error);
      });
  } catch (error) {
    console.error(error);
  }
  if (Object.keys(data).map((key) => data[key]).length < 4) {
    return [];
  } else {
    let dup = getUnique(
      Object.keys(data).map((key) => data[key]),
      'id'
    );

    return sortBy(dup, function (row) {
      return row.Name;
    });
  }
};

export default GetData;
