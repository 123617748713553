import './HomePage.css';
import { useHistory, Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementActions } from '../Store/ElementSlice';
import Assumptions from './Assumptions';
import { toggleActions } from '../Store/TogglePopup';
import { Box, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { version } from '../../../package.json';
import SelectFac from '../../components/LoadFacility/SelectFac';
import { authProvider } from '../../authProvider';
import { css } from '@emotion/react';
import FadeLoader from 'react-spinners/FadeLoader';
import axios from 'axios';
import Notification from 'components/Navigation_bar/Notification';
import { ConfigActions } from 'components/Store/ConfigSlice';
import UploadFac from '../../components/LoadFacility/UploadFac';
import Popup from '../PopupForm/Popup';

const config = require('../../config');

const override = css`
  position: relative;
  font-size: 0;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  display: block;
  margin: 0px auto;
`;

const HomePage = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [dropDown, setDropDown] = useState();
  const [loading, setLoading] = useState(false);
  const accountInfo = authProvider.getAccountInfo();
  const account = accountInfo.account;
  const userName = account.userName;
  const isAssump = useSelector((state) => state.toggleForm.isAssumptions);
  const isLoaded = useSelector((state) => state.toggleForm.isLoaded); // to
  const isUploadFile = useSelector((state) => state.toggleForm.isUploadFile);
  const isUploadFac = useSelector((state) => state.toggleForm.isUploadFac);

  const toggleAssump = () => {
    dispatch(toggleActions.toggleAssumptions());
  };

  const toggleUpload = () => {
    dispatch(toggleActions.uploadFac());
  };
  async function isLoadFacility() {
    setLoading(true);

    let apiGateway = {};
    console.log(window.location.hostname);
    switch (window.location.hostname) {
      case 'pearl.ai.fmgaws.cloud': {
        // prod
        apiGateway = {
          API_KEY: config.default.apiGatewayProd.API_KEY,
          API_HOST: config.default.apiGatewayProd.API_HOST,
        };
        break;
      }
      case 'pearl-test.ai.fmgaws.cloud': {
        // test
        apiGateway = {
          API_KEY: config.default.apiGatewayTest.API_KEY,
          API_HOST: config.default.apiGatewayTest.API_HOST,
        };
        break;
      }
      default: {
        // dev
        apiGateway = {
          API_KEY: config.default.apiGatewayDev.API_KEY,
          API_HOST: config.default.apiGatewayDev.API_HOST,
        };
      }
    }
    try {
      axios
        .get(apiGateway.API_HOST + '/facility/user/' + userName, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'x-api-key': apiGateway.API_KEY, // prod
          },
        })
        .then((res) => {
          console.log(`statusCode: ${res.status}`);
          // console.log('respond facility ids', JSON.stringify(res, null, 2));
          setDropDown(res);
          dispatch(toggleActions.setIsLoaded());
          if (res.status === 200) {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const createID = () => {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
  };

  const OpenFacHandler = () => {
    const FacId = createID();
    const conId = createID();
    dispatch(elementActions.addFacilityId(FacId));
    dispatch(ConfigActions.updateConfigID(conId));
    history.push('/App');
    dispatch(elementActions.ResetElements());
    dispatch(toggleActions.toggleFacForm(true));
    dispatch(toggleActions.setTour(true));
  };

  return (
    <>
      {' '}
      <div>{!isUploadFile && <Notification />}</div>
      <div>
        <div className="cont"> </div>

        <div className="Footer_con">
          <div className="Footer_left">
            <Typography display="inline">
              {'Copyright © '} {new Date().getFullYear()} Fortescue Future Industries
              {'.'}
            </Typography>
          </div>
          <div className="Footer_right">
            <Typography display="inline">
              Version {version}
              {'.'}
            </Typography>
          </div>
        </div>
        {loading ? (
          <div className="loading">
            <FadeLoader color="black" loading={loading} css={override} speedMultiplier={1} size={150} />
            <h2> Loading </h2>
          </div>
        ) : (
          <>
            {!isAssump && !isLoaded && !isUploadFac ? (
              <div className="bg-text">
                <div>
                  <label>Welcome to PEARL</label>
                </div>
                <div className="nav_buttons">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs="auto">
                        <button id="create_new" className="btn far fa-plus-square" onClick={OpenFacHandler}>
                          &nbsp;&nbsp;Create a new Facility
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button id="load_existing" className="btn fas fa-external-link-alt" onClick={isLoadFacility}>
                          Open an Existing Facility
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button id="upload_fac" className="btn fas fa-external-link-alt" onClick={toggleUpload}>
                          Import Facility
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button className="btn far fa-clipboard">
                          <a
                            href="Key_Definitions.pdf"
                            style={{ color: 'white', textDecoration: 'none' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            &nbsp;&nbsp;Key Definitions
                          </a>{' '}
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button className="btn fas fa-info-circle " onClick={toggleAssump}>
                          Assumptions
                        </button>
                      </Grid>
                      <Grid item xs="auto">
                        <button className="btn">
                          <a
                            href="PEARL user guide.pdf"
                            style={{ color: 'white', textDecoration: 'none' }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PEARL user guide
                          </a>{' '}
                          &nbsp;
                          <Link
                            to="PEARL user guide.pdf"
                            target="_blank"
                            style={{ color: 'white', cursor: 'alias' }}
                            download="PEARL user guide.pdf"
                          >
                            <i className="fas fa-download" />
                          </Link>
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
            ) : isLoaded ? (
              <SelectFac facData={dropDown} />
            ) : isUploadFac ? (
              <Popup type="facupload" trigger={isUploadFac} setTrigger={toggleUpload}>
                <UploadFac />
              </Popup>
            ) : isAssump ? (
              <Assumptions />
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;
